import { z } from "zod";

export const getSchema = (param) => {
  const result = {};
  const defaultSchema = z.string().min(1, "Required");

  param.forEach(({ inputForm, required = true, schema, name }) => {
    if (schema) {
      result[name] = schema;
    } else if (!required) {
      result[name] = z.any().optional();
    } else {
      switch (inputForm) {
        case "SimpleInput":
        case "TextareaInput":
        case "NumberInput":
        case "DateInput":
        case "SelectInput":
        case "SelectApiInput":
        case "SelectApiInputGroup":
        case "SelectCarModel":
        case "CustomComponant":
          result[name] = defaultSchema;
          break;
        case "CheckBox":
          result[name] = z.boolean();
          break;
        default:
          console.warn(`Unknown input form: ${inputForm}`);
          break;
      }
    }
  });

  return z.object(result);
};

import React, { Fragment } from "react";
import { Center, Space, Button } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";

import Translate from "Components/Translate";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { COPIED, COPY_SERIAL_NUMBERS } from "Constant";

const TaximetersComp = ({ data }) => {
  const clipboard = useClipboard({ timeout: 2000 });

  const handleCopy = () => {
    const serialNumbers = data.map((item) => item.serial_number).join("-");
    clipboard.copy(serialNumbers);
  };

  return (
    <Fragment>
      <DataTable value={data} responsiveLayout="scroll" size="small">
        <Column field="serial_number" header="Serial Number"></Column>
        <Column field="sim_iccid" header="SIM ICCID"></Column>
        <Column field="sim_phone" header="SIM Phone"></Column>
      </DataTable>

      <Space h="md" />

      {/* Copy Button */}
      <Center>
        <Button color="green" onClick={handleCopy}>
          {clipboard.copied ? (
            <Translate>{COPIED}</Translate>
          ) : (
            <Translate>{COPY_SERIAL_NUMBERS}</Translate>
          )}
        </Button>
      </Center>

      {/* Feedback when copied */}
    </Fragment>
  );
};

export default TaximetersComp;

import React from "react";
import styles from "./Loading.module.css";
import logo from "assets/images/logo/logo_final_taxiora.svg";

const Loading = () => {
  return (
    <div className={styles["loading-bar"]}>
      <img src={logo} alt="Logo" className={styles["logo-spinner"]} />
    </div>
  );
};

export default Loading;

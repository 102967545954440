import React from "react";

import SimpleInput from "./SimpleInput";
import DateInput from "./DateInput";
import SelectInput from "./SelectInput";
import SelectApiInput from "./SelectApiInput";
import SelectApiInputGroup from "./SelectApiInputGroup";
import NumberInput from "./NumberInput";
import TextareaInput from "./TextareaInput";
import CheckBox from "./CheckBox";
import SelectCarModel from "./SelectCarModel";

const FilterInput = ({ item, handleChange, oldValue, errors, control }) => {
  switch (item.inputForm) {
    case "SimpleInput":
      return (
        <SimpleInput
          register={handleChange}
          inputName={item}
          errors={errors}
          oldValue={oldValue}
        />
      );
    case "TextareaInput":
      return (
        <TextareaInput
          register={handleChange}
          inputName={item}
          errors={errors}
          oldValue={oldValue}
        />
      );
    case "NumberInput":
      return (
        <NumberInput
          register={handleChange}
          inputName={item}
          errors={errors}
          oldValue={oldValue}
          control={control}
        />
      );

    case "DateInput":
      return (
        <DateInput
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
          errors={errors}
          control={control}
        />
      );
    case "SelectInput":
      return (
        <SelectInput
          control={control}
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
          errors={errors}
        />
      );
    case "SelectApiInput":
      return (
        <SelectApiInput
          control={control}
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
          errors={errors}
        />
      );
    case "SelectApiInputGroup":
      return (
        <SelectApiInputGroup
          control={control}
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
          errors={errors}
        />
      );
    case "CheckBox":
      return (
        <CheckBox
          control={control}
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
          errors={errors}
        />
      );
    case "SelectCarModel":
      return (
        <SelectCarModel control={control} oldValue={oldValue} errors={errors} />
      );

    case "CustomComponant":
      return item.comp;

    default:
      return null;
  }
};

export default FilterInput;

import DriversInfo from "pages/SheetOfClientPage/DriversInfo";
import React from "react";
import { isEmpty } from "./isEmpty";
import { UPDATE_DRIVERS } from "Constant";
import UpdateBtn from "./UpdateBtn";

const UsersComp = ({ data, taxiData }) => {
  if (isEmpty(data)) return isEmpty(data);

  const taximeterCode = taxiData?.taximeter?.code_taximeter ?? null;
  const urlUpdate = `/back/taximeter/${taximeterCode}/drivers`;

  const client_code = taxiData?.client?.code_client;

  const disabled = taxiData?.taximeter?.state === "free" ? false : true;

  const updateBtn = (
    <UpdateBtn title={UPDATE_DRIVERS} path={urlUpdate} disabled={disabled} />
  );
  return (
    <DriversInfo data={data} updateBtn={updateBtn} client_code={client_code} />
  );
};

export default UsersComp;

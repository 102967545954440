import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Center, Group } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import useFetchData from "hooks/useFetchDataWithDep";

import CustomActions from "./CustomActions";
import RefreshBtn from "./RefreshBtn";

import Translate from "Components/Translate";

import { THERE_IS_NO_DATA_FOUND } from "Constant";

import {
  DEFAULT_COLUMN_FILTERS,
  DEFAULT_COLUMN_PINNING,
  DEFAULT_PAGINATION,
  DEFAULT_SORTING,
  PAGINATION_CHOICES,
  getRows,
} from "./data";

const DataTable = ({
  newColumns,
  link,
  createBtn,
  renderDetailPanel,
  mantineTableBodyRowProps,
  defSorting = DEFAULT_SORTING,
  defPagination = DEFAULT_PAGINATION,
  defColumnFilters = DEFAULT_COLUMN_FILTERS,
  columnPinning = DEFAULT_COLUMN_PINNING,
  CustomComponent,
}) => {
  const { refresh } = useSelector((state) => state.searchLink);
  const { t } = useTranslation();
  const columns = transferColumns(newColumns, t);

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState(defColumnFilters);
  const [pagination, setPagination] = useState(defPagination);
  const [sorting, setSorting] = useState(defSorting);

  const url = useMemo(() => {
    const params = new URLSearchParams();
    params.append("size", pagination.pageSize);
    params.append("index", pagination.pageIndex + 1);
    if (globalFilter) {
      params.append("search", globalFilter);
    }
    columnFilters.forEach((filter) => {
      if (filter.value) {
        params.append(filter.id, filter.value);
      }
    });
    sorting.forEach((filter) => {
      const val = filter.desc ? "DESC" : "ASC";
      params.append("sort_order", val);
      params.append("sort_column", filter.id);
    });
    return `${link}?${params.toString()}`;
  }, [
    pagination.pageSize,
    pagination.pageIndex,
    globalFilter,
    columnFilters,
    sorting,
  ]);

  const { data, isLoading, isError } = useFetchData(url, url, refresh);
  const { total: paginationTotalRows, rows: listOfData } = getRows(data);

  const onGlobalFilterChange = (event) => {
    setGlobalFilter(event);
  };

  const table = useMantineReactTable({
    columns: columns,
    data: listOfData,
    rowCount: paginationTotalRows,
    initialState: {
      pagination: pagination,
      columnPinning: columnPinning,
    },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    manualPagination: true,
    mantinePaginationProps: {
      showRowsPerPage: true,
      rowsPerPageOptions: PAGINATION_CHOICES(paginationTotalRows),
    },
    enableStickyHeader: true,
    mantineTableProps: {
      highlightOnHover: true,
      striped: "odd",
      withColumnBorders: true,
      withRowBorders: true,
      withTableBorder: true,
    },
    enableColumnResizing: true,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enablePinning: true,
    layoutMode: "flex",
    paginationDisplayMode: "pages",
    renderDetailPanel: renderDetailPanel,

    renderTopToolbarCustomActions: ({ table }) => (
      <Group gap="xs">
        {createBtn === undefined ? <CustomActions table={table} /> : createBtn}
        {CustomComponent && React.createElement(CustomComponent, { table })}
        <RefreshBtn />
      </Group>
    ),

    onGlobalFilterChange: onGlobalFilterChange,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    enableGlobalFilter: true,
    enableColumnFilters: true,

    renderEmptyRowsFallback: () => (
      <Center inline style={{ margin: "2%" }}>
        <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
      </Center>
    ),

    state: {
      isLoading: isLoading,
      showAlertBanner: false,
      showProgressBars: false,
      pagination,
      sorting,
    },

    mantineTableBodyRowProps: mantineTableBodyRowProps,
  });

  return <MantineReactTable table={table} />;
};

export default DataTable;

// Helper functions
const transferColumns = (list, t) =>
  list.map((col) => ({
    ...col,
    accessorKey: col.selector ?? col.accessorKey,
    header: t(col.name || col.header),
    enableColumnFilter: col.enableColumnFilter ?? true,
    enableSorting: col.enableSorting ?? col.enableSorting,
  }));

import { Button, Center } from "@mantine/core";
import Translate from "Components/Translate";
import React from "react";

import {
  areYouSureAlert,
  changedAlert,
  ShowErrorMsg,
} from "Components/Alerts/Alerts";

import { toggleRefresh } from "../../redux/searchLink";
import { store } from "../../redux/store";

import axiosClient from "Services/axiosClient";
import { IconSend2, IconUserScreen } from "@tabler/icons-react";

const UpdateBtn = ({ title, path, disabled }) => {
  const handleUpdate = () => {
    areYouSureAlert().then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .request({
            url: path,
            method: "LINK", // Forcing the method to "LINK"
          })
          .then(() => {
            changedAlert();
            store.dispatch(toggleRefresh());
          })
          .catch((error) => {
            console.error("Request failed:", error);
            ShowErrorMsg(error);
          });
      }
    });
  };

  return (
    <Center>
      <Button
        color="orange"
        onClick={handleUpdate}
        leftSection={<IconUserScreen size={14} />}
        rightSection={<IconSend2 size={14} />}
        disabled={disabled}
      >
        <Translate>{title}</Translate>
      </Button>
    </Center>
  );
};

export default UpdateBtn;

import WorkOrdersPage from "pages/WorkOrdersPage";
import ReadWordingPage from "pages/ReadWordingPage";

import PrimitivesPage from "pages/PrimitivesPage";
import ReadPrimitivePage from "pages/ReadPrimitivePage";
import ReadWorkOrderPage from "pages/ReadWorkOrderPage";

const PRODUCTION_ROUTES = [
  {
    path: "/work-order",
    element: <WorkOrdersPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/work-order/:code",
    element: <ReadWorkOrderPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/primitives",
    element: <PrimitivesPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/primitive/:code",
    element: <ReadPrimitivePage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
];

export default PRODUCTION_ROUTES;

import { getCodeLabel } from "../../helper/getOptions";

// API
export const API = {
  list: "/back/rawmaterialsIssues",
  get: "/back/rawmaterialsIssues",
  // GET_BACKUSER_AGENCIES: "/back/backuser/",
  create: "/back/rawmaterialissues",
  //GROUPS_CHOICES: "/back/groups",
};

// Columns
export const COLUMNS = [
  {
    name: "category",
    selector: "category",
  },
  {
    name: "model",
    selector: "model",
  },
  {
    name: "Quantity",
    selector: "quantity",
  },
  {
    name: "Created at",
    selector: "created_at",
  },
  { name: "Updated At", selector: "updated_at" },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
  {
    title: "Quantity",
    placeholder: "Quantity",
    name: "quantity",
    inputForm: "SimpleInput",
  },

  {
    title: "Commentary",
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
  {
    title: "Quantity",
    placeholder: "Quantity",
    name: "quantity",
    inputForm: "SimpleInput",
  },

  {
    title: "Commentary",
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];

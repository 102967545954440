import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

import { Group, Spoiler } from "@mantine/core";

import {
  CLIENT,
  COORDINATES,
  HIDE,
  MESSAGE,
  PAYMENT_DATE,
  PRICE,
  SHOW_MORE,
  TAXI,
} from "Constant";
import Translate from "Components/Translate";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import { createdAt } from "Constant/objs";
import { formatDate } from "helper/dateFormat";

// Urls
export const API = {
  list: "/back/paymentNotifications",
};

// Columns
export const COLUMNS = [
  {
    accessorKey: "price",
    header: PRICE,
    filterVariant: "range-slider",
    filterFn: "betweenInclusive",
    mantineFilterRangeSliderProps: {
      max: 1_000,
      min: 30,
      step: 1,
      label: (value) =>
        value.toLocaleString("en-TN", {
          style: "currency",
          currency: "TND",
        }),
    },
  },
  {
    accessorFn: (originalRow) => originalRow.payment_date,
    id: "payment_date",
    header: PAYMENT_DATE,
    Cell: ({ row }) => {
      const { payment_date } = row.original;
      return formatDate(payment_date);
    },
  },
  {
    header: TAXI,
    size: 300,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",
    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;
      console.log(row.original);

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },

  {
    header: CLIENT,
    size: 300,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",
    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;
      console.log(row.original);

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: MESSAGE,
    id: "message",
    Cell: ({ row }) => {
      const { message } = row.original;
      return (
        <Spoiler
          maxHeight={30}
          showLabel={<Translate>{SHOW_MORE}</Translate>}
          hideLabel={<Translate>{HIDE}</Translate>}
        >
          {message}
        </Spoiler>
      );
    },
  },
  createdAt,
];

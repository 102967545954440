import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";

import { Controller } from "react-hook-form";
import useFetchData from "hooks/useFetchData";

import { getData } from "helper/getData";

import Translate from "Components/Translate";

const SelectApiInputGroup = ({
  register,
  inputName,
  errors,
  oldValue,
  control,
}) => {
  const url = "/back/models/choices/car";
  const { data, isLoading, isError } = useFetchData(url);

  if (isLoading) return null;

  const carList = getData(data);

  return (
    <Main
      register={register}
      inputName={inputName}
      errors={errors}
      oldValue={oldValue}
      control={control}
      data={carList}
    />
  );
};

const Main = ({ register, inputName, errors, oldValue, control, data }) => {
  const [filter, setFilter] = useState("");
  const { name, title, required } = inputName;
  const isReq = required === undefined ? true : false;

  const groupedItemTemplate = (option) => {
    return (
      <Divider align="center">
        <b>{option.label}</b>
      </Divider>
    );
  };

  const filteredData = filtredData(data, filter);

  return (
    <>
      <div className="col-3">
        <div className="flex flex-column ">
          <label>
            <Translate>{title}</Translate>
            {isReq && <span className="text-danger">*</span>}
          </label>

          <Controller
            name={name}
            control={control}
            defaultValue={oldValue}
            render={({ field }) => (
              <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                  console.log(e);
                }}
                invalid={errors[name]}
                optionLabel="label"
                options={filteredData}
                optionGroupLabel="label"
                optionGroupChildren="models"
                optionValue="code"
                optionGroupTemplate={groupedItemTemplate}
                filter
                filterTemplate={
                  <div className="p-2">
                    <input
                      type="text"
                      placeholder="..."
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      className="p-inputtext p-component"
                      style={{ width: "100%" }}
                    />
                  </div>
                }
              />
            )}
          />

          {errors[name] && (
            <small className="text-danger">
              <Translate>{errors[name].message}</Translate>
            </small>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectApiInputGroup;

const filtredData = (data, filter) => {
  console.log("!filter", !filter);

  if (!filter) {
    return data;
  }

  const lowercasedFilter = filter.toLowerCase();

  // Filter the data based on the filter value
  return data
    .map((group) => {
      // Filter the models within each group
      const filteredModels = group.models.filter((model) =>
        model.label.toLowerCase().includes(lowercasedFilter)
      );

      // If any models match, return the group with filtered models
      if (filteredModels.length > 0) {
        return { ...group, models: filteredModels };
      }

      // Otherwise, if the group label matches, return the entire group
      if (group.label.toLowerCase().includes(lowercasedFilter)) {
        return group;
      }

      // If no match, return null
      return null;
    })
    .filter(Boolean); // Remove any null values
};

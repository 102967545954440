import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import AddItem from "Components/AddItemWithZod";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS, CREATE_ITEMS } from "./data";

import { CREATE_SERVICE, MY_SERVICES } from "Constant";
import RenderDetailPanel from "./RenderDetailPanel";

const PricingServicePage = () => {
  const list = API.list;
  const breadCrumbsProps = [MY_SERVICES];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <DataTables
            newColumns={COLUMNS}
            link={list}
            renderDetailPanel={({ row }) => <RenderDetailPanel data={row} />}
          />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SERVICE}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default PricingServicePage;

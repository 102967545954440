import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import EmptyData from "Components/EmptyData";
import Loading from "Components/Loading/Loading";

import useFetchData from "hooks/useFetchDataWithDep";

import { useDispatch, useSelector } from "react-redux";
import { handleChangeData } from "../../redux/datasheetSlicer";
import {
  IconCar,
  IconCircuitVoltmeter,
  IconClockDollar,
  IconUser,
  IconUsersGroup,
  IconWifi,
} from "@tabler/icons-react";
import {
  CLIENT,
  DRIVERS,
  GENERAL_DATA,
  SERVICE,
  SERVICES,
  TAXI,
  TAXIMETER,
} from "Constant";

import TaximeterComp from "./TaximeterComp";
import NotebookComp from "./NotebookComp";
import TaximeterTotalsComp from "./TaximeterTotalsComp";
import ClientComp from "./ClientComp";
import TaxiComp from "./TaxiComp";
import ProfileComp from "./ProfileComp";
import LuminousComp from "./LuminousComp";
import PrinterComp from "./PrinterComp";
import UsersComp from "./UsersComp";
import ServicesComp from "./ServicesComp";

import { Helmet } from "react-helmet";
import { Paper, Text, Timeline } from "@mantine/core";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import Translate from "Components/Translate";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useTranslation } from "react-i18next";
import { PrinterIcon } from "lucide-react";

const SheetOfTaximeterPage = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const { refresh } = useSelector((state) => state.datasheet);
  const { t } = useTranslation();

  const url = `/back/taximeter/${code}/datasheet`;
  const { data, isLoading, isError } = useFetchData(url, refresh);

  const taximeterSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;
  if (isError) return <EmptyData />;

  // dispatch(handleChangeData("taximeterSheet"));

  // TaximeterComp
  // NotebookComp
  // TaximeterTotalsComp
  // ClientComp
  // TaxiComp
  // ProfileComp
  // LuminousComp
  // PrinterComp
  // UsersComp
  // ServicesComp

  const stepsList = [
    {
      accordion: false,
      icon: <IconCircuitVoltmeter size={30} />,
      title: GENERAL_DATA,
      content: <TaximeterComp data={taximeterSheet} />,
    },
    {
      accordion: false,
      icon: <IconCircuitVoltmeter size={30} />,
      title: TAXIMETER,
      content: <TaximeterTotalsComp />,
    },
    {
      accordion: false,
      icon: <IconUser size={30} />,
      title: GENERAL_DATA,
      content: <NotebookComp />,
    },
    {
      accordion: false,
      icon: <IconUser size={30} />,
      title: CLIENT,
      content: <ClientComp />,
    },
    {
      accordion: false,
      icon: <IconCar size={30} />,
      title: TAXI,
      content: <TaxiComp />,
    },
    {
      accordion: false,
      icon: <IconUser size={30} />,
      title: GENERAL_DATA,
      content: <ProfileComp />,
    },

    {
      accordion: false,
      icon: <IconUser size={30} />,
      title: GENERAL_DATA,
      content: <LuminousComp />,
    },

    {
      accordion: false,
      icon: <PrinterIcon size={30} />,
      title: GENERAL_DATA,
      content: <PrinterComp />,
    },

    {
      accordion: false,
      icon: <IconUsersGroup size={30} />,
      title: DRIVERS,
      content: <UsersComp />,
    },

    {
      accordion: false,
      icon: <IconWifi size={30} />,
      title: SERVICES,
      content: <ServicesComp />,
    },
  ];

  const breadCrumbsProps = [t(TAXIMETER) + " " + code];
  return (
    <Fragment>
      <Helmet>
        <title>{"clientName"}</title>
      </Helmet>

      <Paper shadow="xs" withBorder p="xl">
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
        <Timeline color="orange" active={stepsList.length} bulletSize={40}>
          {stepsList.map((elem, i) => {
            const title = (
              <Text fw={700} size="lg">
                <Translate>{elem.title}</Translate>
              </Text>
            );

            if (elem.accordion === false)
              return (
                <Timeline.Item bullet={elem.icon} key={i}>
                  {elem.content}
                </Timeline.Item>
              );
            return (
              <Timeline.Item bullet={elem.icon} key={i}>
                <Accordion>
                  <AccordionTab header={title}>{elem.content}</AccordionTab>
                </Accordion>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Paper>
    </Fragment>
  );
};

export default SheetOfTaximeterPage;

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

import React, { Fragment } from "react";
import {
  IconHammer,
  IconBrandProducthunt,
  IconListCheck,
} from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { Badge, Paper, Text, Timeline } from "@mantine/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useFetchData from "hooks/useFetchDataWithDep";

import Loading from "Components/Loading/Loading";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import Translate from "Components/Translate";

import {
  CLIENT,
  GENERAL_DATA,
  PRODUCTS,
  TASKS,
  TAXIMETER,
  TOTLA_NUMBER,
  WORK_ORDER,
} from "Constant";

import GeneralComp from "./GeneralComp";
import TasksComp from "./TasksComp";
import ProductsComp from "./ProductsComp";
import EditWorkOrder from "pages/WorkOrdersPage/EditWorkOrder";

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

const ReadWordingPage = () => {
  const { code } = useParams();
  const { t } = useTranslation();

  const { refresh } = useSelector((state) => state.searchLink);

  const url = `/back/workOrder/${code}`;
  const { data, isLoading, isError } = useFetchData(url, refresh);

  const taxiSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;

  if (isError) return null;

  const { tasks, products } = taxiSheet;
  const generalData = taxiSheet;

  const taskTitle = (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Translate>{TASKS}</Translate>
      <Badge color="blue" size="lg">
        <Translate>{TOTLA_NUMBER}</Translate> : {tasks.length}
      </Badge>
    </div>
  );

  const prodTitle = (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Translate>{PRODUCTS}</Translate>
      <Badge color="blue" size="lg">
        <Translate>{TOTLA_NUMBER}</Translate> : {products.length}
      </Badge>
    </div>
  );

  const stepsList = [
    {
      icon: <IconHammer size={30} />,
      title: GENERAL_DATA,
      content: <GeneralComp data={generalData} />,
    },
    {
      icon: <IconListCheck size={30} />,
      title: taskTitle,
      content: <TasksComp data={tasks} />,
    },
    {
      icon: <IconBrandProducthunt size={30} />,
      title: prodTitle,
      content: <ProductsComp data={products} />,
    },
  ];

  const dynamicPart = `${t(WORK_ORDER)} ${generalData.woNumber}`;
  const breadCrumbsProps = [dynamicPart];

  return (
    <Fragment>
      <Paper shadow="xs" withBorder p="xl">
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

        <Timeline color="orange" active={stepsList.length} bulletSize={40}>
          {stepsList.map((elem, i) => {
            const title = (
              <Text fw={700} size="lg">
                <Translate>{elem.title}</Translate>
              </Text>
            );

            return (
              <Timeline.Item bullet={elem.icon} title={title} key={i}>
                {elem.content}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Paper>
      <EditWorkOrder />
    </Fragment>
  );
};

export default ReadWordingPage;

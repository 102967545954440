import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

import { Group, Spoiler } from "@mantine/core";

import { COORDINATES, HIDE, SHOW_MORE } from "Constant";
import Translate from "Components/Translate";

// Urls
export const API = {
  create: "/back/polygon",
  list: "/back/polygons",
  edit: "/back/polygon/",
  patch: "/back/polygon/",
  delete: "/back/polygon/",
};

// Columns
export const COLUMNS = [
  { name: "Label", selector: "label" },

  {
    header: COORDINATES,
    id: "coordinates",
    Cell: ({ row }) => {
      const { coordinates } = row.original;
      return (
        <Spoiler
          maxHeight={30}
          showLabel={<Translate>{SHOW_MORE}</Translate>}
          hideLabel={<Translate>{HIDE}</Translate>}
        >
          {coordinates}
        </Spoiler>
      );
    },
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 50,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.patch + code} state={active} />
          <EditBtn read={API.edit + code} extra={label} />
          <DeleteBtn delete={API.delete + code} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "TitleInput",
  },
  {
    title: "Coordinates",

    placeholder: "Coordinates",
    name: "coordinates",
    inputForm: "TextareaInput",
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];
export const EDIT_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "TitleInput",
  },
  {
    title: "Coordinates",

    placeholder: "Coordinates",
    name: "coordinates",
    inputForm: "TextareaInput",
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];

import React, { Fragment, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Space } from "@mantine/core";

import Translate from "Components/Translate";

import { taxiInp } from "./data";
import SelectApiInput from "./SelectApiInput";

import {
  COEFFICIENT,
  DEPOSIT,
  REMAINIG_AMOUNT,
  TAXI,
  TIME_SCALE,
} from "Constant";
import { getEstiamteCoefficient } from "helper/estimateFx";

const EstimateDetails = ({ client, inputs, register, remainingAmount }) => {
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value >= 0 || value === "")
      register((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    register((prevState) => ({
      ...prevState,
      estimate_coefficient: getEstiamteCoefficient(inputs["timescale"]),
    }));
  }, [inputs["timescale"]]);

  return (
    <Fragment>
      <Space w="md" />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {/* Taxi */}
        <Fragment key={client}>
          <div style={{ flex: "1", minWidth: "150px" }}>
            <label>
              <Translate>{TAXI}</Translate>
            </label>
            <SelectApiInput
              register={(e) => {
                register((prevState) => ({ ...prevState, taxi: e.value }));
              }}
              inputName={taxiInp(client)}
              isDisabled={!client}
              oldValue={inputs.taxi}
            />
          </div>
        </Fragment>

        {/* Deposit */}
        <div
          style={{
            flex: "1",
            minWidth: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
            <Translate>{DEPOSIT}</Translate>
          </label>
          <InputNumber
            value={inputs["deposit"]}
            onValueChange={(e) => {
              register((prevState) => ({
                ...prevState,
                deposit: e.value,
              }));
            }}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            min={0}
          />
        </div>

        {/* Time scale */}
        <div
          style={{
            flex: "1",
            minWidth: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
            <Translate>{TIME_SCALE}</Translate>
          </label>
          <InputNumber
            value={inputs["timescale"]}
            onValueChange={(e) => {
              register((prevState) => ({
                ...prevState,
                timescale: e.value,
              }));
            }}
            mode="decimal"
            minFractionDigits={0}
            maxFractionDigits={0}
            min={0}
          />
        </div>

        {/* Coefficient */}
        <div
          style={{
            flex: "1",
            minWidth: "150px",
            display: "flex",
            flexDirection: "column",
            margin: "0 0.5rem",
          }}
        >
          <label style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
            <Translate>{COEFFICIENT}</Translate>
          </label>
          <InputNumber
            disabled={inputs["type"] === "service"}
            onValueChange={(e) => {
              register((prevState) => ({
                ...prevState,
                estimate_coefficient: e.value,
              }));
            }}
            value={inputs["estimate_coefficient"]}
            mode="decimal"
            minFractionDigits={1}
            maxFractionDigits={2}
            min={0}
            max={100}
            suffix="%"
            step={1}
          />
        </div>
        {/* Remainig Amount */}
        <div
          style={{
            flex: "1",
            minWidth: "150px",
            display: "flex",
            flexDirection: "column",
            margin: "0 0.5rem",
          }}
        >
          <label style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
            <Translate>{REMAINIG_AMOUNT}</Translate>
          </label>
          <InputNumber disabled={true} value={remainingAmount} mode="decimal" />
        </div>
      </div>
    </Fragment>
  );
};

export default EstimateDetails;

import { DEPOSIT, PRODUCT, TAXI, TYPE } from "Constant";
import {
  getCodeLabel,
  getModelsLabel,
  getServiceLabel,
} from "helper/getOptions";

export const clientInp = {
  title: "Client",
  name: "client",
  optionsApi: "/back/client",
  getOptions: getCodeLabel,
  required: false,
  width: "4",
};

export const estiamteTypeOption = [
  {
    label: "Produit",
    value: "product",
  },
  {
    label: "Service",
    value: "service",
  },
];

export const estimateType = {
  title: TYPE,
  name: "type",
  required: false,
  width: "4",
  options: estiamteTypeOption,
};

export const taxiInp = (taxi) => ({
  title: TAXI,
  name: "taxi",
  optionsApi: `/back/client/${taxi}/taxis/choices`,
  getOptions: getCodeLabel,
  required: false,
  width: "4",
});

export const depositInp = {
  title: DEPOSIT,
  name: "deposit",
};

export const categoryInp = {
  title: "Category",
  name: "category",
  optionsApi: "/back/inventory-categories",
  getOptions: getCodeLabel,
};

export const modelInp = (category) => ({
  title: "Model",
  name: "model",
  optionsApi: `/back/models/choices/${category}`,
  getOptions: getModelsLabel,
});

export const serviceInp = {
  title: "Service",
  name: "service",
  inputForm: "SelectApiInput",
  optionsApi: "/back/services/choices",
  getOptions: getCodeLabel,
};

export const getNumber = (param) => {
  if (isNaN(param)) return 0;

  return parseFloat(param).toFixed(1);
};

export const timeScaleOptions = [
  {
    label: 0,
    value: 0,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 12,
    value: 12,
  },
];

export const initEstimateD = {
  timescale: 0,
  remainingAmount: 0,
  deposit: 0,
  estimate_coefficient: 0.0,
  type: "product",
};

export const initError = {
  client: false,
  taxi: false,
  remainingAmount: false,
};

import { Fragment } from "react";
import { Button } from "primereact/button";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import FilterInput from "Components/InputsWithZod";
import { getSchema } from "helper/schema";
import CancelModalBtn from "Components/ModalApp/CancelModalBtn";
const SimpleForm = ({ items, oldData, onSubmit, cancel = false }) => {
  const schema = getSchema(items);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap">
          {items.map((elem, i) => (
            <Fragment key={i}>
              <FilterInput
                control={control}
                item={elem}
                handleChange={register}
                errors={errors}
                oldValue={oldData ? oldData[elem.name] : null}
              />
            </Fragment>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1%",
            padding: "5px",
          }}
        >
          {cancel && <CancelModalBtn />}

          <Button
            type="submit"
            label="Go"
            severity="success"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Fragment>
  );
};

export default SimpleForm;

import grayMarker from "./markers/gray_marker.png";
import greenMarker from "./markers/green_marker.png";
import redMarker from "./markers/red_marker.png";

import L from "leaflet";

const statusTanslate = {
  free: "Libre",
  off: "hors service",
  busy: "Occupé",
};

export const getColor = (status) => {
  let color;
  switch (status) {
    case "off":
      color = "gray"; // Path to the gray marker icon
      break;
    case "free":
      color = "green"; // Path to the green marker icon
      break;
    case "busy":
      color = "red"; // Path to the red marker icon
      break;
    default:
      color = "gray"; // Default marker icon if the status is unknown
  }
  return color;
};

export const getIcon = (status) => {
  let iconUrl;
  switch (status) {
    case "off":
      iconUrl = grayMarker;
      break;
    case "free":
      iconUrl = greenMarker;
      break;
    case "busy":
      iconUrl = redMarker;
      break;
    default:
      iconUrl = grayMarker;
  }

  return L.icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  });
};

export const statusCheckbox = (status) => {
  return statusTanslate[status] ?? status;
};

export const getInitPosition = () => {
  // const SOUSSE_INIT_MAP_POSITION = [35.83422413, 10.59211575];
  const PARIS_INIT_MAP_POSITION = [48.83985850901064, 2.3367042128437276];

  try {
    return process.env.REACT_APP_INIT_MAP_POSITION
      ? JSON.parse(process.env.REACT_APP_INIT_MAP_POSITION)
      : PARIS_INIT_MAP_POSITION;
  } catch {
    return PARIS_INIT_MAP_POSITION;
  }
};

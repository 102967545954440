import React from "react";
import { Badge } from "@mantine/core";

import { getColor, statusCheckbox } from "./data";

const TaxiDetailsBox = ({ taxis }) => {
  const freeCount = taxis.filter((taxi) => taxi.status === "free").length;
  const busyCount = taxis.filter((taxi) => taxi.status === "busy").length;
  const offCount = taxis.filter((taxi) => taxi.status === "off").length;

  return (
    <div
      style={{
        position: "absolute",
        bottom: "20px", // Added padding to the bottom
        right: "20px", // Added padding to the right
        backgroundColor: "transparent",
        padding: "10px 15px", // Adjusted padding for better spacing
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
        backdropFilter: "blur(10px)",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: 5,
          width: "15vw",
        }}
      >
        <Badge
          fullWidth
          size="xl"
          color={getColor("free")}
          leftSection={`${statusCheckbox("free")}: ${freeCount}`}
        />

        <Badge fullWidth size="xl" color={getColor("busy")}>
          {statusCheckbox("busy")}: {busyCount}
        </Badge>

        <Badge fullWidth size="xl" color={getColor("off")}>
          {statusCheckbox("off")}: {offCount}
        </Badge>
      </div>
    </div>
  );
};

export default TaxiDetailsBox;

// Columns
export const COLUMNS = [
  {
    name: "Content",
    selector: "content",
  },
  {
    name: "Language",
    selector: "language",
  },
  {
    name: "Created at",
    selector: "created_at",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Domain",
    placeholder: "Domain",
    name: "domain",
    inputForm: "SimpleInput",
  },
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Language",
    placeholder: "Language",
    name: "language",
    inputForm: "SimpleInput",
  },
  {
    title: "Content",
    placeholder: "Content",
    name: "content",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Language",
    placeholder: "Language",
    name: "language",
    inputForm: "SimpleInput",
  },
  {
    title: "Content",
    placeholder: "Content",
    name: "content",
    inputForm: "SimpleInput",
  },
];

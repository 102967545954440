import React, { Fragment } from "react";

import styles from "./style.module.css";

const StaticTextTable = ({ requiredData, title }) => {
  return (
    <Fragment>
      <div className={styles["text-table"]}>
        {{ title } && (
          <div className={styles["text-table-title"]}> {title} </div>
        )}
        {requiredData.map((elem, index) => (
          <div key={index} className={styles["text-table-item"]}>
            <span>{elem.label}</span>
            <span>{elem.value}</span>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default StaticTextTable;

import React from "react";
import ClientInfo from "pages/SheetOfClientPage/ClientInfo";
import { isEmpty } from "./isEmpty";

const ClientComp = ({ data }) => {
  if (isEmpty(data)) return isEmpty(data);
  console.log("data:", data);

  // const {
  //   company,
  //   phone,
  //   registry,
  //   address,
  //   city,
  //   zip_code,
  //   pro_card,
  //   pro_card_got_at,
  //   pro_card_expire_at,
  //   client_active,
  //   image,
  //   tester,
  //   client_cni,
  //   client_licence,
  // } = data;
  return <ClientInfo data={data} toClientSheet />;
};

export default ClientComp;

import { Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import ActionsBtns from "./ActionsBtns";
import {
  IconArrowBackUp,
  IconSend2,
  IconTrash,
  IconTruckDelivery,
} from "@tabler/icons-react";

import {
  CANCEL,
  COMMENTARY,
  COMPANY_ADDRESS,
  COMPANY_CITY,
  COMPANY_CONTAT_NAME,
  COMPANY_NAME,
  COMPANY_ZIP_CODE,
  DELIVER,
  RETURNED,
  SEND,
} from "Constant";

// API
export const API = {
  list: "/back/primitives",
  get: "/back/primitive/",
  create: "/back/primitive",
};

// Columns
export const COLUMNS = [
  {
    name: "Number",
    selector: "pr_number",
  },

  {
    name: "Taximeters number",
    selector: "count_taximeters",
  },

  {
    name: "Status",
    selector: "status",
  },

  {
    name: "Company",
    selector: "company",
  },

  {
    name: "Complete name",
    selector: "complete_name",
  },

  {
    name: "Address",
    selector: "address",
  },

  {
    name: "Creator",
    selector: "creator",
  },

  {
    name: "Updator",
    selector: "updator",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 160,
    Cell: ({ row }) => {
      const { code, to_cancel, to_send, to_deliver, to_return, pr_number } =
        row.original;
      const sentUrl = `/back/primitive/${code}/send`;
      const cancelUrl = `/back/primitive/${code}/cancel`;
      const deliverUrl = `/back/primitive/${code}/deliver`;
      const returnedUrl = `/back/primitive/${code}/returned`;

      return (
        <Group gap="xs">
          <ReadPageBtn read={`/primitive/${code}`} />
          <ActionsBtns
            url={sentUrl}
            icon={<IconSend2 />}
            title={`${SEND} primitive N° ${pr_number}`}
            disbled={!to_send}
          />

          <ActionsBtns
            url={deliverUrl}
            icon={<IconTruckDelivery />}
            title={`${DELIVER} primitive N° ${pr_number}`}
            disbled={!to_deliver}
          />
          <ActionsBtns
            url={returnedUrl}
            icon={<IconArrowBackUp />}
            title={`${RETURNED} primitive N° ${pr_number}`}
            disbled={!to_return}
          />
          <ActionsBtns
            url={cancelUrl}
            icon={<IconTrash />}
            title={`${CANCEL} primitive N° ${pr_number}`}
            disbled={!to_cancel}
          />
        </Group>
      );
    },
  },
];

export const CREATE_ITEMS = [
  {
    title: COMPANY_NAME,
    placeholder: COMPANY_NAME,
    name: "companyName",
    inputForm: "SimpleInput",
  },
  {
    title: COMPANY_CONTAT_NAME,
    placeholder: COMPANY_CONTAT_NAME,
    name: "companyContactName",
    inputForm: "SimpleInput",
  },
  {
    title: COMPANY_ADDRESS,
    placeholder: COMPANY_ADDRESS,
    name: "companyAddress",
    inputForm: "SimpleInput",
  },

  {
    title: COMPANY_CITY,
    placeholder: COMPANY_CITY,
    name: "companyCity",
    inputForm: "SimpleInput",
  },
  {
    title: COMPANY_ZIP_CODE,
    placeholder: COMPANY_ZIP_CODE,
    name: "companyZipCode",
    inputForm: "SimpleInput",
  },
  {
    title: COMMENTARY,
    placeholder: COMMENTARY,
    name: "commentary",
    inputForm: "SimpleInput",
  },
];

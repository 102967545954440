import React, { Fragment } from "react";
import { Button, Menu, rem } from "@mantine/core";

import patchItem from "Services/patchItem";

import OpenModalBtn from "Components/ModalApp/OpenModalBtn";
import Translate from "Components/Translate";

import { CONFIRM, CREATE_DELIVERY_FORM, PREPARE, READY } from "Constant";
import {
  IconCheckbox,
  IconCircleDashedCheck,
  IconEye,
  IconTools,
  IconTruckDelivery,
} from "@tabler/icons-react";

export const ConfirmPaymentBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={<IconCheckbox style={{ width: rem(14), height: rem(14) }} />}
      variant="filled"
      color="green"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CONFIRM}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={<IconCheckbox style={{ width: rem(14), height: rem(14) }} />}
      variant="filled"
      color="green"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CONFIRM}</Translate>
    </Menu.Item>
  );
};

export const CreateDeliveryFormBtn = ({ disabled, button, data }) => {
  return button ? (
    <Button
      leftSection={
        <IconTruckDelivery style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="blue"
      disabled={disabled}
    >
      <Translate>{CREATE_DELIVERY_FORM}</Translate>
    </Button>
  ) : (
    <Fragment>
      <OpenModalBtn
        type={"createCreateDelivaryForm"}
        title={data.po_number}
        data={data}
      >
        <Menu.Item
          leftSection={
            <IconTruckDelivery style={{ width: rem(14), height: rem(14) }} />
          }
          disabled={disabled}
        >
          <Translate>{CREATE_DELIVERY_FORM}</Translate>
        </Menu.Item>
      </OpenModalBtn>
    </Fragment>
  );
};

export const PrepareDeliveryFormBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={<IconTools style={{ width: rem(14), height: rem(14) }} />}
      variant="filled"
      color="orange"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{PREPARE}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={<IconTools style={{ width: rem(14), height: rem(14) }} />}
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{PREPARE}</Translate>
    </Menu.Item>
  );
};

export const ReadyDeliveryFormBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={
        <IconCircleDashedCheck style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="orange"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={
        <IconCircleDashedCheck style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Menu.Item>
  );
};

import { Group, Spoiler } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";
import { ANSWERS, HIDE, QUESTIONS, SHOW_MORE } from "Constant";
import Translate from "Components/Translate";

export const API = {
  list: "/back/faqs",
  edit: "/back/faq/",
  delete: "/back/faq/",
  create: "/back/faqs",
};

export const COLUMNS = [
  {
    header: QUESTIONS,
    id: "question",
    Cell: ({ row }) => {
      const { question } = row.original;
      return (
        <Spoiler
          maxHeight={50}
          showLabel={<Translate>{SHOW_MORE}</Translate>}
          hideLabel={<Translate>{HIDE}</Translate>}
        >
          {question}
        </Spoiler>
      );
    },
  },

  {
    header: ANSWERS,
    id: "answer",
    Cell: ({ row }) => {
      const { answer } = row.original;
      return (
        <Spoiler
          maxHeight={30}
          showLabel={<Translate>{SHOW_MORE}</Translate>}
          hideLabel={<Translate>{HIDE}</Translate>}
        >
          {answer}
        </Spoiler>
      );
    },
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 30,
    Cell: ({ row }) => {
      const { question, code } = row.original;
      return (
        <Group gap="xs">
          <EditBtn edit={API.edit + code} extra={question} />
          <DeleteBtn delete={API.delete + code} />
        </Group>
      );
    },
  },
];

export const CREATE_ITEMS = [
  {
    title: "Question",
    placeholder: "question",
    name: "question",
    inputForm: "SimpleInput",
  },
  {
    title: "Answer",
    placeholder: "answer",
    name: "answer",
    inputForm: "SimpleInput",
  },
];

import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
//import AddItem from "Components/AddItem";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS } from "./data";

import {
  CREATE_DELIVERY_FORM_FROM_PURCHASE_ORDER,
  MY_PURCHASE_ORDERS,
} from "Constant";

import CreateDelivaryForm from "./CreateDelivaryForm";

const PurchaseOrderPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_PURCHASE_ORDERS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} createBtn={false} />
        </CardBody>
      </Card>

      <ModalApp
        type={"createCreateDelivaryForm"}
        title={CREATE_DELIVERY_FORM_FROM_PURCHASE_ORDER}
      >
        <CreateDelivaryForm />
      </ModalApp>
    </Fragment>
  );
};

export default PurchaseOrderPage;

import React, { Fragment } from "react";
import { IconHammer, IconListCheck } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { Paper, Text, Timeline } from "@mantine/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useFetchData from "hooks/useFetchDataWithDep";

import Loading from "Components/Loading/Loading";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import Translate from "Components/Translate";

import { GENERAL_DATA, TAXIMETERS, PRIMITVE } from "Constant";

import GeneralComp from "./GeneralComp";
import TaximetersComp from "./TaximetersComp";

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

const ReadPrimitivePage = () => {
  const { code } = useParams();
  const { t } = useTranslation();

  const { refresh } = useSelector((state) => state.searchLink);

  const url = `/back/primitive/${code}`;
  const { data, isLoading, isError } = useFetchData(url, refresh);

  const result = getRowsFromRes(data);

  if (isLoading) return <Loading />;

  if (isError) return null;

  const { primitive, taximeters } = result;

  const stepsList = [
    {
      icon: <IconHammer size={30} />,
      title: GENERAL_DATA,
      content: <GeneralComp data={result} />,
    },
    {
      icon: <IconListCheck size={30} />,
      title: TAXIMETERS,
      content: <TaximetersComp data={taximeters} />,
    },
  ];

  const dynamicPart = `${t(PRIMITVE)} ${result.prNumber}`;
  const breadCrumbsProps = [dynamicPart];

  return (
    <Fragment>
      <Paper shadow="xs" withBorder p="xl">
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

        <Timeline color="orange" active={stepsList.length} bulletSize={40}>
          {stepsList.map((elem, i) => {
            const title = (
              <Text fw={700} size="lg">
                <Translate>{elem.title}</Translate>
              </Text>
            );

            return (
              <Timeline.Item bullet={elem.icon} title={title} key={i}>
                {elem.content}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Paper>
    </Fragment>
  );
};

export default ReadPrimitivePage;

import React from "react";
import { Table } from "@mantine/core";
import HandleResult from "helper/HandleResult";
import Translate from "Components/Translate";

const SimpleTable = ({ requiredKeys }) => {
  return (
    <div
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        overflowX: "auto",
        padding: "10px", // Added padding for better spacing
        scrollbarWidth: "thin", // For Firefox
        scrollbarColor: "#888 #e0e0e0", // Thumb and track color for Firefox
      }}
    >
      <Table highlightOnHover withTableBorder withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            {requiredKeys.map((elem, i) => (
              <Table.Th key={i} style={{ whiteSpace: "nowrap" }}>
                <Translate>{elem.title}</Translate>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            {requiredKeys.map((elem, i) => (
              <Table.Td key={i} style={{ whiteSpace: "nowrap" }}>
                {elem.fn ? elem.key : <HandleResult data={elem.key} />}
              </Table.Td>
            ))}
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </div>
  );
};

export default SimpleTable;

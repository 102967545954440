import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Tooltip, Menu, rem, ActionIcon } from "@mantine/core";

import {
  IconEdit,
  IconDots,
  IconAdjustments,
  IconEye,
} from "@tabler/icons-react";

import { EDIT_TAXI, GO_TO_TAXI_SHEET, SETTINGS } from "Constant";

import Translate from "Components/Translate";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";

import { useDispatch } from "react-redux";
import { changeModalType, changeTitle } from "../../../redux/modal";
import { changeCurrentRow } from "../../../redux/currentRow";
import { toggleRefresh } from "../../../redux/searchLink";

const TaxiSettings = ({ data, taxiData }) => {
  const dispatch = useDispatch();

  const code_client = taxiData?.client?.code_client;
  const { taxi_serial_number, code_taxi, active_taxi } = data;

  const url = `/back/client/${code_client}/taxi/${code_taxi}`;
  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editTaxi"));
    dispatch(changeTitle(taxi_serial_number));
    dispatch(changeCurrentRow(selectedRow));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };
  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate> {SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              <Translate>{EDIT_TAXI}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconAdjustments style={{ width: rem(14), height: rem(14) }} />
              }
            >
              <ActiveBtn state={active_taxi} patch={url} refresh={refresh} />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export default TaxiSettings;

import {
  Accordion,
  Avatar,
  Center,
  Group,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconHammer } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  CREATED_AT,
  CREATOR,
  DESCRIPTION,
  EXECUTOR,
  UPDATED_AT,
} from "Constant";
import { SettingsBtn } from "pages/WorkOrdersPage/SettingsBtn";

const GeneralComp = ({ data }) => {
  const {
    woNumber,
    description,
    createdAt,
    updatedAt,
    status,
    creator,
    executor_name,
  } = data;

  const title = `N° ${woNumber}`;

  const requiredKeys = [
    { title: EXECUTOR, key: executor_name },
    { title: CREATOR, key: creator },
    { title: DESCRIPTION, key: description },

    { title: CREATED_AT, key: createdAt },
    { title: UPDATED_AT, key: updatedAt },
    { title: "Status", key: status },
  ];
  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Avatar radius="xl" size="lg" color="orange">
                  {<IconHammer color="black" />}
                </Avatar>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    {title}
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            <SettingsBtn data={data} />
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <SimpleTable requiredKeys={requiredKeys} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default GeneralComp;

import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import {
  CREATE_RAW_MATERIAL_STOCK,
  EDIT_RAW_MATERIAL_STOCK,
  MY_RAW_MATERIAL_STOCKS,
  PURCHASE_ORDER_OF_RAW_MATERIAL_STOCK,
} from "Constant";

import StockCart from "./StockCart";

const RMStockPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_RAW_MATERIAL_STOCKS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_RAW_MATERIAL_STOCK}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_RAW_MATERIAL_STOCK}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="cart" title={PURCHASE_ORDER_OF_RAW_MATERIAL_STOCK}>
        <StockCart />
      </ModalApp>
    </Fragment>
  );
};

export default RMStockPage;

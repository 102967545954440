import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Center, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import useFetchData from "hooks/useFetchDataWithDep";

import CustomActions from "./CustomActions";
import RefreshBtn from "./RefreshBtn";

import EmptyData from "Components/EmptyData";
import { DEFAULT_COLUMN_PINNING, getRowsWithouPaggination } from "./data";

const DataTableWithouPaggination = ({
  newColumns,
  link,
  createBtn,
  getRows = getRowsWithouPaggination,
  columnPinning = DEFAULT_COLUMN_PINNING,
}) => {
  const { refresh } = useSelector((state) => state.searchLink);
  const { t } = useTranslation();
  const columns = transferColumns(newColumns, t);

  const url = link;

  const { data, isLoading, isError } = useFetchData(url, url, refresh);

  const { rows: listOfData } = getRows(data);

  const table = useMantineReactTable({
    columns: columns,
    data: listOfData,

    initialState: {
      columnPinning: columnPinning,
    },

    createDisplayMode: "modal",
    editDisplayMode: "modal",
    getRowId: (row) => row.id,
    enableStickyHeader: true,
    mantineTableProps: {
      highlightOnHover: true,
      striped: "odd",
      withColumnBorders: true,
      withRowBorders: true,
      withTableBorder: true,
    },
    enableColumnResizing: true,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enablePinning: true,
    layoutMode: "flex",

    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <Group gap="xs">
          {createBtn === undefined ? (
            <CustomActions table={table} />
          ) : (
            createBtn
          )}
          <RefreshBtn />
        </Group>
      );
    },
    renderEmptyRowsFallback: () => (
      <Center inline style={{ margin: "2%" }}>
        <EmptyData />
      </Center>
    ),
    state: {
      isLoading: isLoading,
      showAlertBanner: false,
      showProgressBars: false,
    },
  });

  return <MantineReactTable table={table} />;
};

export default DataTableWithouPaggination;

const transferColumns = (list, t) =>
  list.map((col) => ({
    ...col,
    accessorKey: col.selector ?? col.accessorKey,
    header: t(col.name || col.header),
    enableColumnFilter: col.enableColumnFilter ?? true,
    enableSorting: col.enableSorting ?? !!col.name,
  }));

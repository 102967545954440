import { createSlice } from "@reduxjs/toolkit";

export const datasheetSlicer = createSlice({
  name: "datasheet",

  initialState: {
    data: {},
    refresh: false,
  },

  reducers: {
    handleChangeData: (state, action) => {
      const newData = action.payload;
      state.data = newData;
    },
  },
});

export const { handleChangeData } = datasheetSlicer.actions;

export default datasheetSlicer.reducer;

import {
  Accordion,
  Avatar,
  Center,
  Divider,
  Group,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconHammer } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  ADDRESS,
  CITY,
  COMMENTARY,
  COMPANY,
  CONTACT,
  CREATED_AT,
  CREATOR,
  DESCRIPTION,
  EXECUTOR,
  STATUS,
  UPDATED_AT,
  UPDATOR,
  ZIP_CODE,
} from "Constant";
import Translate from "Components/Translate";

const GeneralComp = ({ data }) => {
  console.log(data);

  const {
    prNumber,
    companyName,
    companyContactName,
    companyAddress,
    companyCity,
    companyZipcode,

    createdAt,
    updatedAt,
    commentary,
    status,
    creator,
    updator,
  } = data;
  console.log(data);

  const title = `N° ${prNumber}`;

  const requiredKeys = [
    // COMPANY

    { title: COMMENTARY, key: commentary },
    { title: STATUS, key: status },
    // { title: CREATOR, key: creator },
    // { title: UPDATOR, key: updator },

    { title: CREATED_AT, key: createdAt },
    { title: UPDATED_AT, key: updatedAt },
  ];

  const companyRequiredKeys = [
    // COMPANY
    { title: COMPANY, key: companyName },
    { title: CONTACT, key: companyContactName },
    { title: ADDRESS, key: companyAddress },
    { title: CITY, key: companyCity },
    { title: ZIP_CODE, key: companyZipcode },
  ];
  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Avatar radius="xl" size="lg" color="orange">
                  {<IconHammer color="black" />}
                </Avatar>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    {title}
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <SimpleTable requiredKeys={requiredKeys} />
              <Divider
                my="xs"
                label={
                  <Text fw={700}>
                    <Translate>{COMPANY}</Translate>
                  </Text>
                }
                labelPosition="center"
              />
              <SimpleTable requiredKeys={companyRequiredKeys} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default GeneralComp;

import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Space } from "@mantine/core";
import { useDispatch } from "react-redux";

import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import Translate from "Components/Translate";

import { editProduct } from "../../redux/estimateSlicer";

import {
  CATEGORY,
  DESCRIPTION,
  DISCOUNT,
  DISCOUNTED_PRICE,
  MODEL,
  PRODUCT,
  QUANTITY,
  TTC,
  UNITY_PRICE,
  VAT,
} from "Constant";

import { DeleteProductBtn } from "./EstimatesBtns";
import SelectApiInput from "./SelectApiInput";
import { categoryInp, getNumber, modelInp } from "./data";

import axiosClient from "Services/axiosClient";

import { getPriceVat } from "helper/getData";
import { calculateProduct } from "helper/estimateFx";

const initPrices = {
  price_ttc: 0,
  vat: 0,
  price_ht: 0,
};

const initInp = {
  category: "",
  model: "",
  description: "",
  price: 0,
  quantity: 1,
  vat: 0,
  discount: 0,
};

export default function OneProduct({ index, oldData }) {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState(oldData ? oldData : initInp);
  const [prices, setPrices] = useState(initPrices);

  const { vat, price_ht } = prices;

  const price = calculateProduct(price_ht, 0, inputs.quantity, inputs.discount);
  const price_ttc = calculateProduct(
    price_ht,
    vat,
    inputs.quantity,
    inputs.discount
  );

  const finalRes = useMemo(
    () => ({
      ...inputs,
      price,
      price_ttc,
      vat,
    }),
    [inputs, price, price_ttc, vat]
  );

  useEffect(() => {
    if (inputs.model) {
      axiosClient(`/back/model/${inputs.model}/price`)
        .then((res) => {
          setPrices(getPriceVat(res));
        })
        .catch((error) => {
          setPrices(initPrices);
          console.error(error);
        });
    }
  }, [inputs.model]);

  useEffect(() => {
    dispatch(editProduct({ data: finalRes, index: index }));
  }, [dispatch, finalRes, index]);

  return (
    <Fragment>
      <Panel
        headerTemplate={
          <div className="p-panel-header justify-content-space-between">
            <div className="flex align-items-center ">
              <span className="font-bold">
                <Translate>{PRODUCT}</Translate>: {index + 1}
              </span>
            </div>
            <div>
              <DeleteProductBtn i={index} />
            </div>
          </div>
        }
        toggleable
      >
        <div className="grid">
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "150px" }}>
              <label htmlFor="discription">
                <Translate>{CATEGORY}</Translate>
              </label>
              <SelectApiInput
                register={(e) => {
                  const newVal = { [e.name]: e.value, model: "" };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                inputName={categoryInp}
                oldValue={inputs["category"]}
              />
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label htmlFor="discription">
                <Translate>{MODEL}</Translate>
              </label>
              <Fragment key={inputs.category}>
                <SelectApiInput
                  register={(e) => {
                    const newVal = { [e.name]: e.value };
                    setInputs((preState) => ({ ...preState, ...newVal }));
                  }}
                  inputName={modelInp(inputs.category)}
                  isDisabled={!inputs.category}
                  oldValue={inputs["model"]}
                />
              </Fragment>
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{QUANTITY}</Translate>
              </label>
              <InputNumber
                onValueChange={(e) => {
                  const newVal = { quantity: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                min={1}
                showButtons
                size={10}
                value={inputs["quantity"]}
              />
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{DESCRIPTION}</Translate>
              </label>
              <InputText
                onChange={(e) => {
                  const newVal = { description: e.target.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                value={inputs["description"]}
              />
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{DISCOUNT}</Translate>%
              </label>
              <InputNumber
                onValueChange={(e) => {
                  const newVal = e.value
                    ? { discount: parseFloat(e.value.toFixed(2)) }
                    : { discount: 0 };

                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                mode="decimal"
                minFractionDigits={1}
                maxFractionDigits={2}
                min={0}
                max={100}
                suffix="%"
                showButtons
                step={1}
                size={10}
                value={inputs["discount"]}
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{UNITY_PRICE}</Translate>
              </label>
              <InputNumber
                value={getNumber(price_ht)}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>

          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{DISCOUNTED_PRICE}</Translate>
              </label>
              <InputNumber
                value={getNumber(finalRes["price"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>

          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{VAT}</Translate>
              </label>
              <InputNumber
                value={getNumber(vat)}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
                suffix="%"
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{TTC}</Translate>
              </label>
              <InputNumber
                value={getNumber(finalRes["price_ttc"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
        </div>
      </Panel>

      <Space h="lg" />
    </Fragment>
  );
}

import { Group } from "@mantine/core";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import { COMPLETE_NAME, USERS } from "Constant";
import { createdAt, updatedAt } from "Constant/objs";

// Urls
export const API = {
  list: "/back/operators",
  create: "/back/operator",
  get: "/back/operator/",
  edit: "/back/operator/",
};

// Columns
export const COLUMNS = [
  {
    header: COMPLETE_NAME,
    id: "completeName",
    accessorFn: (originalRow) => originalRow.complete_name,
    button: "true",
    size: 120,
    Cell: ({ row }) => {
      const { company, civility, firstname, lastname } = row.original;
      const completeName = `${company} /${civility} ${firstname} ${lastname}`;
      return completeName;
    },
  },
  {
    name: USERS,
    selector: "count_users",
  },

  createdAt,
  updatedAt,
  {
    header: "Actions",
    id: "actions",
    button: "true",
    Cell: ({ row }) => {
      const { code, active } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/operator/${code}`} disabled={true} />
          <EditBtn read={API.get + code} disabled={true} />
          <ActiveBtn patch={API.get + code} state={active} />
        </Group>
      );
    },
  },
];

export const CREATE_ITEMS = [];
export const EDIT_ITEMS = [];

import { Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Loading from "../Loading/Loading";
import DataNotFound from "../NotFound/DataNotFound";

import getRowsFromRes from "../../helper/getRowsFromRes";

import errorMsg from "../../Services/errorMsg";
import axiosClient from "../../Services/axiosClient";
import generateSearchLink from "../../Services/generateSearchLink";

import { ALL_LIST, OF, ROWS_PER_PAGE } from "../../Constant";

import {
  handleChangeIndex,
  handleChangeSize,
  initializeSearchItems,
} from "../../redux/searchLink";
import EmptyData from "Components/EmptyData";

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
      },
    },
  },
};

const DataTables = ({ newColumns, link }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { searchItem, refresh, renderDataTable } = useSelector(
    (state) => state.searchLink
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const searchLink = isEmptyObject(searchItem)
    ? link
    : generateSearchLink(link, searchItem);

  const columns = transformColumns(newColumns, t);

  const fetch = () => {
    setIsLoading(true);
    axiosClient
      .get(searchLink)
      .then((res) => {
        setData(res);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setData([]);
        setIsLoading(false);
        setIsError(true);
        console.error(err);
        errorMsg(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetch();
    }, 250); // 1000 milliseconds (1 second) delay

    return () => clearTimeout(timer);
  }, [searchLink, refresh]);

  useEffect(() => {
    return () => {
      dispatch(initializeSearchItems());
    };
  }, [newColumns]);

  const { total: paginationTotalRows, rows: dataTableData } =
    getRowsFromRes(data);

  const handlePerRowsChange = (newPerPage, page) => {
    const payload = { index: page, size: newPerPage };
    dispatch(handleChangeSize(payload));
  };

  const handlePageChange = (page) => {
    dispatch(handleChangeIndex(page));
  };

  const paginationComponentOptions = {
    rowsPerPageText: t(ROWS_PER_PAGE),
    rangeSeparatorText: t(OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(ALL_LIST),
  };

  if (isError) return <DataNotFound />;

  return (
    <Fragment key={renderDataTable}>
      <Card>
        <CardBody>
          <DataTable
            columns={columns}
            progressPending={isLoading}
            progressComponent={
              <div style={{ marginBottom: "100px" }}>
                <Loading />
              </div>
            }
            data={dataTableData}
            striped="true"
            center="true"
            wrap="true"
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationComponentOptions={paginationComponentOptions}
            onChangePage={handlePageChange}
            noDataComponent={<EmptyData />}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DataTables;

function transformColumns(columns, t) {
  if (!columns) return [];

  return columns.map((column) => {
    if (column.button && typeof column.name === "string")
      return { ...column, name: t(column.name) };
    if (column.button) return column;

    const transformedColumn = {
      name: t(column.name),
      selector: (row) => row[column.selector],
      sortable: "true",
      center: "true",
      wrap: "true",
    };

    return transformedColumn;
  });
}

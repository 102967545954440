import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";

import { API, COLUMNS, SEARCH_ITEMS } from "./data";

import { MY_ORDERS_OF_PRIMITIVES } from "../../Constant";

const RMPrimitivesPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_ORDERS_OF_PRIMITIVES];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader />

          <FilterBar searchByItems={SEARCH_ITEMS} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default RMPrimitivesPage;

import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import AddItem from "Components/AddItemWithZod";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS, CREATE_ITEMS } from "./data";

import { CREATE_PRIMITIVE, MY_ORDERS_OF_PRIMITIVES } from "Constant";

const PrimitivesPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_ORDERS_OF_PRIMITIVES];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_PRIMITIVE}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default PrimitivesPage;

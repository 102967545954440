const checkData = (data, type) => {
  return true;

  try {
    if (!data.client) return false; // Client is required
    if (!data.taxi) return false; // Taxi is required
    if (typeof data.deposit !== "number") return false; // Deposit must be a number
    if (!data.price || isNaN(data.price)) return false; // Price must be valid
    if (!data.priceVat || isNaN(data.priceVat)) return false; // Price VAT must be valid
    if (!data.remainingAmount || isNaN(data.remainingAmount)) return false; // Remaining amount must be valid
    if (!data.vatAmount || isNaN(data.vatAmount)) return false; // VAT amount must be valid
    if (data.timescale === undefined || data.timescale === null) return false; // Time scale must be valid

    if (type === "product") {
      const { products } = data;
      if (!products || products.length === 0) return false; // At least one product is required
      for (const prod of products) {
        if (!prod.category) return false; // Product category is required
        if (!prod.model) return false; // Product model is required
        if (!prod.price || isNaN(prod.price)) return false; // Product price must be valid
        if (!prod.vat || isNaN(prod.vat)) return false; // Product VAT must be valid
        if (prod.quantity === undefined || prod.quantity === null) return false; // Product quantity must be valid
      }
    }

    if (type === "service") {
      const { services } = data;
      if (!services || services.length === 0) return false; // At least one service is required
      for (const service of services) {
        if (!service.name) return false; // Service name is required
        if (!service.price || isNaN(service.price)) return false; // Service price must be valid
      }
    }

    return true; // All validations passed, disable the button
  } catch (error) {
    console.error("Validation error:", error.message);
    return false; // Validation error occurred, enable the button
  }
};

export default checkData;

import { SegmentedControl } from "@mantine/core";
import { PRODUCTS, SERVICES } from "Constant";
import React, { Fragment, useState } from "react";
import PricingServicePage from "./PricingServicePage";
import PricingProductPage from "./PricingProductPage";
import { Card, CardBody } from "reactstrap";
import Translate from "Components/Translate";

const PricingPages = () => {
  const [state, setState] = useState("SERVICES");

  const componants = {
    SERVICES: <PricingServicePage />,
    PRODUCTS: <PricingProductPage />,
  };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <SegmentedControl
            transitionDuration={200}
            transitionTimingFunction="linear"
            color="yellow"
            fullWidth
            size="md"
            value={state}
            onChange={setState}
            data={[
              { label: <Translate>{SERVICES}</Translate>, value: "SERVICES" },
              { label: <Translate>{PRODUCTS}</Translate>, value: "PRODUCTS" },
            ]}
          />

          {componants[state]}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PricingPages;

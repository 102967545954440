import Breadcrumbs from "Components/Breadcrumbs";
import { EDIT_PROFILE } from "Constant";
import React from "react";
import { Card, CardBody } from "reactstrap";

const EditMyProfile = () => {
  const breadCrumbsProps = [EDIT_PROFILE];

  return (
    <Card className="mb-0">
      <CardBody>
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
      </CardBody>
    </Card>
  );
};

export default EditMyProfile;

import { Fragment } from "react";

import {
  Group,
  Avatar,
  Text,
  Accordion,
  Badge,
  Center,
  Indicator,
  Space,
  Paper,
  Divider,
  Grid,
  CopyButton,
  ActionIcon,
  Tooltip,
  rem,
} from "@mantine/core";

import { IconCar, IconCopy, IconCheck } from "@tabler/icons-react";

import { Image } from "primereact/image";

import HeaderTab from "./HeaderTab";
import ListOfTaxiData from "./ListOfTaxiData";
import { CreateTaxi, TaxisSettingsBtn } from "./SettingsBtn";

import {
  ACTIVE,
  DISABLED,
  DOCUMENTS,
  LICENCE,
  NO_TAXIMETER_FOUND,
  REGISTRATION,
  TAXIMETER,
  THERE_IS_NO_DATA_FOUND,
} from "Constant";
import Translate from "Components/Translate";

function TaxisInfo({ data }) {
  return (
    <Fragment>
      <HeaderTab tab={[<CreateTaxi />]} />

      <Accordion chevronPosition="left" variant="contained">
        {data.map((elem, i) => {
          const id = i.toString();
          const { taxi_serial_number, active_taxi, taxi_cg, taxi_licence } =
            elem;
          const taxiTitle = `${elem.brand_taxi} / ${elem.model_taxi} / ${elem.taxi_serial_number}`;

          const title = (
            <Text fw={700}>
              <Badge variant="dot" color="gray">
                <Text size="lg">{taxiTitle}</Text>
              </Badge>
            </Text>
          );

          const indicatorProps = active_taxi
            ? { color: "green", label: <Translate>{ACTIVE}</Translate> }
            : { color: "red", label: <Translate>{DISABLED}</Translate> };

          return (
            <Fragment>
              <Accordion.Item value={id} key={taxi_serial_number}>
                <Center>
                  <Accordion.Control>
                    <Group wrap="nowrap">
                      <Indicator
                        label={indicatorProps.label}
                        color={indicatorProps.color}
                      >
                        <Avatar radius="xl" size="lg" color="orange">
                          <IconCar color="black" />
                        </Avatar>
                      </Indicator>

                      <Space w="md" />

                      <div>
                        <Text size="xl">{title}</Text>
                        {/* <Text size="xl">{elem.taximeter}</Text> */}
                        <Space h="xs" />
                        <Text fw={700}>
                          {elem.taximeter ? (
                            <Group gap="xs">
                              <Badge variant="light" color="green">
                                <Text size="lg">
                                  <Translate>{TAXIMETER}</Translate> :
                                  {elem.taximeter}
                                </Text>
                              </Badge>
                              <CopyButton value={elem.taximeter} timeout={2000}>
                                {({ copied, copy }) => (
                                  <Tooltip
                                    label={copied ? "Copied" : "Copy"}
                                    withArrow
                                    position="right"
                                  >
                                    <ActionIcon
                                      color={copied ? "teal" : "gray"}
                                      variant="subtle"
                                      onClick={copy}
                                    >
                                      {copied ? (
                                        <IconCheck style={{ width: rem(16) }} />
                                      ) : (
                                        <IconCopy style={{ width: rem(16) }} />
                                      )}
                                    </ActionIcon>
                                  </Tooltip>
                                )}
                              </CopyButton>
                            </Group>
                          ) : (
                            <Badge variant="light" color="red">
                              <Text size="lg">
                                <Translate>{TAXIMETER}</Translate> :
                                <Translate>{NO_TAXIMETER_FOUND}</Translate>
                              </Text>
                            </Badge>
                          )}
                        </Text>
                        <Text size="sm" color="dimmed" fw={400}>
                          {/* Additional details here */}
                        </Text>
                      </div>
                    </Group>
                  </Accordion.Control>

                  <TaxisSettingsBtn data={elem} />

                  <Space w="lg" />
                </Center>

                <Accordion.Panel>
                  <Paper shadow="xs" withBorder p="xl">
                    <ListOfTaxiData taxiDetails={elem} />

                    {/* taxi_cg taxi_licence */}
                  </Paper>
                </Accordion.Panel>
              </Accordion.Item>
            </Fragment>
          );
        })}
      </Accordion>
    </Fragment>
  );
}

export default TaxisInfo;

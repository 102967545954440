import React, { Fragment } from "react";

import EditItem from "Components/EditItemWithZod";

const EditTaxi = ({ itemsUpdate }) => {
  const getOldData = (data) => {
    console.log("data =", data.data.results.data);

    try {
      const {
        circulation_date,
        serial_number,
        licence_number,
        registration_number,
        licence_got_at,
        licence_expire_at,
        places,
        model,
        sensor,
        profile,
      } = data.data.results.data;

      const res = {
        ...data.data.results.data,
        circulationDate: circulation_date,
        serialNumber: serial_number,
        registrationNumber: registration_number,
        licenceNumber: licence_number,
        licenceGotAt: licence_got_at,
        licenceExpireAt: licence_expire_at,
        sensor: sensor,
        places: places,
        model: model,
        profile: profile,
      };

      return res;
    } catch (error) {
      return [];
    }
  };

  return (
    <Fragment>
      <EditItem itemsUpdate={itemsUpdate} getOldData={getOldData} />
    </Fragment>
  );
};

export default EditTaxi;

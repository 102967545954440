import {
  Accordion,
  Avatar,
  Center,
  Group,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconBrandProducthunt } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import { CREATED_AT, DESCRIPTION, TASKS, TOTAL, TYPE } from "Constant";
import Translate from "Components/Translate";

const TasksComp = ({ data }) => {
  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Avatar radius="xl" size="lg" color="orange">
                  {<IconBrandProducthunt color="black" />}
                </Avatar>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    <Translate>{TASKS}</Translate>
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              {data.map((task, i) => {
                const { total, type, description, created_at } = task;

                const title = `${type}`;

                const requiredKeys = [
                  { title: TYPE, key: type },

                  { title: DESCRIPTION, key: description },
                ];
                return <SimpleTable requiredKeys={requiredKeys} />;
              })}
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default TasksComp;

import React, { Fragment } from "react";
import EmptyData from "./EmptyData";
import { Center } from "@mantine/core";
import Translate from "Components/Translate";
import { THERE_IS_NO_DATA_FOUND } from "Constant";

const DataNotFound = () => {
  return (
    <Center inline style={{ margin: "2%" }}>
      <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
    </Center>
  );
  // return (
  //   <Fragment>
  //     <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
  //       <EmptyData />
  //     </div>
  //   </Fragment>
  // );
};

export default DataNotFound;

import React, { Fragment } from "react";
import { Button, Switch, Tooltip } from "@mantine/core";
import { IconCircleCheck, IconDownload } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";
import Translate from "Components/Translate";
import { PAID } from "Constant";

const DepositProofModal = ({ data }) => {
  const { paid, client, proof, code } = data;
  const fileName = `Preuve_${client}.jpg`;

  const dispatch = useDispatch();

  const changeState = () => {
    const selectedRow = {
      getLink: `/back/schedule/${code}`,
    };
    dispatch(changeModalType("setSchedulePaid"));
    dispatch(changeTitle(client));
    dispatch(changeCurrentRow(selectedRow));
  };

  return (
    <Fragment>
      {paid ? (
        <Tooltip label="Cliquez ici pour ouvrir le preuve">
          <Button
            variant="filled"
            color="green"
            leftSection={<IconCircleCheck size={14} />}
            rightSection={<IconDownload size={14} />}
            disabled={!proof}
            component="a"
            href={proof}
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Recommended for security
          >
            <Translate>{PAID}</Translate>
          </Button>
        </Tooltip>
      ) : (
        <Switch onChange={changeState} color="yellow" checked={false} />
      )}
    </Fragment>
  );
};

export default DepositProofModal;

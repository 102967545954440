import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import { activeObj } from "../../../Constant/objs";
import {
  getCodeLabel,
  getOptionNumber,
  getTitleCodeLabel,
} from "../../../helper/getOptions";

// Urls
export const API = {
  patch: "/back/box/",
  list: "/back/boxes",
  create: "/back/box",
  Serial: "/back/boxes/serials",
  GET_BOXES_CHOICE: "/back/boxes/choices",
  GET_BOX: "/back/box/",
  DELETE_BOX: "/back/box/",
  CHECK_BOX_FOR_SALE: "/back/box/202101052-SC7875S/checksale",
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  { name: "Status", selector: "status" },
  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },
  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn patch={API.patch + row.code} state={row.active} />
    ),
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  activeObj,
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/box",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Firmware",
    name: "firmware",
    inputForm: "SelectApiInput",
    optionsApi: "/back/firmwares/choices?category=BOX",
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/box",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Firmware",
    name: "firmware",
    inputForm: "SelectApiInput",
    optionsApi: "/back/firmwares/choices?category=BOX",
    getOptions: getCodeLabel,
  },
];

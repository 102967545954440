import { Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import React from "react";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { formatDate } from "helper/dateFormat";
import Translate from "Components/Translate";
import { DOWNLOAD_PDF } from "Constant";

const columns = [
  {
    name: "Taxi",
    selector: "taxi",
  },
  {
    name: "Client",
    selector: "client",
  },
  {
    name: "Price",
    selector: "price",
  },
  {
    name: "Paid",
    selector: "paid",
  },
  {
    name: "Type",
    selector: "product",
  },
  {
    name: "Date de paiement",
    selector: "payment_date",
  },

  {
    name: "Créateur",
    selector: "creator",
  },
  {
    name: "Modificateur",
    selector: "updator",
  },
];

const ExportRowsPdf = ({ table }) => {
  const handleExportRows = (rows) => {
    const doc = new jsPDF();

    // Get headers and table data using columns
    const tableHeaders = columns.map((col) => col.name);
    const tableData = rows.map((row) => {
      return Object.values({
        taxi: row.original["taxi"],
        client: row.original["client"],
        price: row.original["price"],
        paid: row.original["paid"] ? "Payé" : "Non payé",
        product: row.original["product"] ? "Produit" : "Service",
        payment_date: formatDate(row.original["payment_date"]),
        creator: row.original["creator"],
        updator: row.original["updator"],
      });
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };
  return (
    <Button
      disabled={table.getPrePaginationRowModel().rows.length === 0}
      onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
      leftSection={<IconDownload size={14} />}
      variant="filled"
      color="orange"
    >
      <Translate>{DOWNLOAD_PDF}</Translate>
    </Button>
  );
};

export default ExportRowsPdf;

import React from "react";
import { useDispatch } from "react-redux";

import { changeModalType, changeTitle } from "../../redux/modal";
import { setCurrentRow } from "../../redux/currentRow";

const OpenModalBtn = ({ type, title, children, data }) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(changeModalType(type));
    dispatch(changeTitle(title));
    dispatch(setCurrentRow(data));
  };

  return <div onClick={handleEdit}>{children}</div>;
};

export default OpenModalBtn;

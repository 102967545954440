import { ActionIcon } from "@mantine/core";
import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import SimpleForm from "Components/SimpleForm";

import patchItemForm from "Services/patchItemForm";

import { COMMENTARY } from "Constant";

const formItem = [
  {
    title: COMMENTARY,
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];

const ActionsBtns = ({ url, icon, title, disbled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    patchItemForm(url, data);
  };
  const toggleModel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <ActionIcon
        size={30}
        variant="default"
        onClick={toggleModel}
        disabled={disbled}
      >
        {icon}
      </ActionIcon>

      <Modal
        isOpen={isOpen}
        toggle={toggleModel}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggleModel}>{title}</ModalHeader>
        <ModalBody>
          <SimpleForm items={formItem} onSubmit={onSubmit} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ActionsBtns;

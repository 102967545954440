import React from "react";
import { Button, Menu, rem } from "@mantine/core";

import Translate from "Components/Translate";

import { CANCEL, CREATE_BILL, DELIVER, READY, RECEIVED } from "Constant";

import patchItem from "Services/patchItem";
import createItemWithPopUp from "Services/createItemWithPopUp";

import {
  IconCircleDashedCheck,
  IconFileInvoice,
  IconReservedLine,
  IconTrash,
  IconTruckDelivery,
} from "@tabler/icons-react";

export const CancelBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
      variant="filled"
      color="red"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CANCEL}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
      variant="filled"
      color="red"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CANCEL}</Translate>
    </Menu.Item>
  );
};

export const ReadyBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={
        <IconCircleDashedCheck style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="orange"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={
        <IconCircleDashedCheck style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Menu.Item>
  );
};

export const DeliverBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={
        <IconTruckDelivery style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="orange"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{DELIVER}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={
        <IconTruckDelivery style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{DELIVER}</Translate>
    </Menu.Item>
  );
};

export const CreateBillBtn = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={
        <IconFileInvoice style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="green"
      onClick={() => createItemWithPopUp(url)}
      disabled={disabled}
    >
      <Translate>{CREATE_BILL}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={
        <IconFileInvoice style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="green"
      size="xs"
      onClick={() => createItemWithPopUp(url)}
      disabled={disabled}
    >
      <Translate>{CREATE_BILL}</Translate>
    </Menu.Item>
  );
};

export const ReceivedDeliveryForm = ({ url, disabled, button }) => {
  return button ? (
    <Button
      leftSection={
        <IconReservedLine style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="green"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{RECEIVED}</Translate>
    </Button>
  ) : (
    <Menu.Item
      leftSection={
        <IconReservedLine style={{ width: rem(14), height: rem(14) }} />
      }
      variant="filled"
      color="green"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{RECEIVED}</Translate>
    </Menu.Item>
  );
};

import React, { Fragment } from "react";

import { Divider, Paper, Space, Text } from "@mantine/core";
import {
  BRAND,
  CIRCULATION_DATE,
  LICENCE,
  LICENCE_GOT_AT,
  LICENCE_TYPE,
  MODEL,
  PLACES,
  REGISTRATION_NUMBER,
  SENSOR,
  SERIAL_NUMBER,
  STATUS,
  TAXI_INFO,
  TAXIMETER,
} from "Constant";
import SimpleTable from "ComponentsMantine/SimpleTable";

const ListOfTaxiData = ({ taxiDetails }) => {
  const {
    licence_type,
    licence,
    licence_got_at,
    licence_expire_at,
    circulation_date,
    taxi_serial_number,
    registration_number,
    model_taxi,
    brand_taxi,
    sensor,
    taximeter,
    places,
    state,
  } = taxiDetails;

  const driverTaxiData = [
    { title: STATUS, key: state },
    { title: SERIAL_NUMBER, key: taxi_serial_number },
    { title: REGISTRATION_NUMBER, key: registration_number },
    { title: MODEL, key: model_taxi },
    { title: BRAND, key: brand_taxi },
    { title: PLACES, key: places },
    { title: LICENCE, key: licence },
    { title: LICENCE_GOT_AT, key: licence_got_at },
    { title: LICENCE_GOT_AT, key: licence_expire_at },
    { title: CIRCULATION_DATE, key: circulation_date },
    // { title: TAXIMETER, key: taximeter },
  ];

  return (
    <Fragment>
      <Space h="md" />

      <Divider
        my="xs"
        label={<Text fw={700}> {TAXI_INFO} </Text>}
        labelPosition="center"
      />
      <SimpleTable requiredKeys={driverTaxiData} />

      {/* <Paper shadow="xs" withBorder p="xl">
        <Divider
          my="xs"
          label={<Text fw={700}> {LICENCE_INFO} </Text>}
          labelPosition="center"
        />
        <SimpleTable requiredKeys={licenceTaxiData} />
      </Paper> */}
    </Fragment>
  );
};

export default ListOfTaxiData;

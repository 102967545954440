import { useState } from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SegmentedControl } from "@mantine/core";

import useFetchData from "hooks/useFetchData";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import { Card, CardBody, Col, Row } from "reactstrap";
import TariffTable from "./TariffTable";
import ParameterTable from "./ParameterTable";
import FlatfeeTable from "./FlatfeeTable";

import {
  TARIFF,
  PARAMETER,
  FLATFEE,
  CREATED_AT,
  UPDATED_AT,
  ACTIVE,
  TIME_ZONE,
  PAUSE,
} from "Constant";
import { AiOutlineIdcard } from "react-icons/ai";
import HeaderTabs from "Components/Header/HeaderTabs";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";

const ReadProfile = () => {
  const { t } = useTranslation();
  const { code } = useParams();

  const [state, setState] = useState("tarif");

  const componants = {
    tarif: <TariffTable profileCode={code} />,
    parameter: <ParameterTable profileCode={code} />,
    flatfee: <FlatfeeTable profileCode={code} />,
  };

  const { data, isLoading, isError } = useFetchData(`/back/profile/${code}`);

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  const profileDetails = getProfileDetails(data);

  const breadCrumbsProps = [profileDetails.label];

  const requiredKeys = [
    { key: "timezone", name: TIME_ZONE, icon: <AiOutlineIdcard /> },
    { key: "created_at", name: CREATED_AT, icon: <AiOutlineIdcard /> },
    { key: "updated_at", name: UPDATED_AT, icon: <AiOutlineIdcard /> },

    { key: "pause", name: PAUSE, icon: <AiOutlineIdcard /> },
    {
      key: "active",
      name: "Active",
      icon: <AiOutlineIdcard />,
      btn: (
        <ActiveBtn
          patch={`/back/profile/${code}`}
          state={profileDetails.active}
        />
      ),
    },
  ];
  return (
    <>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Row>
            <Col sm="2">
              {!isLoading && (
                <HeaderTabs
                  title={breadCrumbsProps}
                  objectData={profileDetails}
                  requiredKeys={requiredKeys}
                />
              )}{" "}
            </Col>
            <Col sm="10">
              <Card className="mb-0">
                <CardBody>
                  <SegmentedControl
                    transitionDuration={200}
                    transitionTimingFunction="linear"
                    color="yellow"
                    fullWidth
                    size="md"
                    value={state}
                    onChange={setState}
                    data={[
                      { label: TARIFF, value: "tarif" },
                      { label: PARAMETER, value: "parameter" },
                      { label: FLATFEE, value: "flatfee" },
                    ]}
                  />
                  <Divider my="md" />

                  {componants[state]}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ReadProfile;

const getProfileDetails = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return {};
  }
};

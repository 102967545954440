import { getCodeLabel } from "../../helper/getOptions";

// Urls
export const API = {
  list: "/back/inventories",
  // GET_INVENTORY: "/back/inventory/",
  create: "/back/inventory",
  // CANCEL_INVENTORY: "/back/inventory/",
  // FINISH_INVENTORY: "/back/inventory/",
  // CREATE_INVENTORY_ITEM: "/back/inventory/",
  // GET_CATEGORIES: "/back/rawmaterial-inventory-categories",
  // GET_MODELS: "/back/rawmaterial/PCB_SENSOR/models",
  // GET_STATUSES: "/back/rawmaterial-inventory-statuses",
};

// Columns
export const COLUMNS = [
  { name: "Number", selector: "number" },
  { name: "Category", selector: "category" },
  { name: "Scanned", selector: "scanned" },
  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];

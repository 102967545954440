import React from "react";
import { useParams } from "react-router-dom";

import useFetchData from "hooks/useFetchData";

import Badge from "./Badge";
import { Skeleton } from "primereact/skeleton";
import { CHANGE_BADGE, CURRENT_BADGE, THERE_IS_NO_DATA_FOUND } from "Constant";
import Translate from "Components/Translate";
import EmptyData from "Components/EmptyData";
import { Group } from "@mantine/core";

const ChangeBadge = ({ data: currenctBadge, driverCode, client_code }) => {
  const { code } = useParams();
  const clientCode = client_code ? client_code : code;

  console.log("currenctBadge", currenctBadge);
  console.log("driverCode", driverCode);

  const url = `/back/client/${clientCode}/badges`;
  const { data, isLoading, isError } = useFetchData(url);

  const badgesList = getBadges(data);

  if (isLoading) return <Skeleton width="5rem" className="mb-2"></Skeleton>;

  if (isError) return currenctBadge ? currenctBadge : <EmptyData />;

  return (
    <Badge
      badgesList={badgesList}
      currenctBadge={currenctBadge}
      driverCode={driverCode}
    />
  );

  // return (
  //   <>
  //     <Group>
  //       <div className="flex flex-column gap-2">
  //         <label>
  //           <Translate>{CHANGE_BADGE}</Translate>
  //         </label>
  //         <Badge
  //           badgesList={badgesList}
  //           currenctBadge={currenctBadge}
  //           driverCode={driverCode}
  //         />
  //       </div>
  //     </Group>
  //   </>
  // );
};

export default ChangeBadge;

const getBadges = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";

import MyProfileEdit from "./MyProfileEdit";
import EditMyProfile from "./EditMyProfile";
import useLocalStorage from "hooks/useLocalStorage";
import useFetchData from "hooks/useFetchData";
import Loading from "Components/Loading/Loading";

const EditProfile = () => {
  const { getData } = useLocalStorage();
  const { code } = getData("userData");

  console.log(getData("userData"));

  const url = `/back/backuser/${code}`;
  const { data, isLoading, isError } = useFetchData(url);

  if (isLoading) return <Loading />;
  if (isError) return null;

  console.log(data);

  return (
    <Fragment>
      <Row>
        <Col xl="4">
          <MyProfileEdit />
        </Col>
        <Col xl="8">
          <EditMyProfile />
        </Col>
      </Row>
    </Fragment>
  );
};

export default EditProfile;

import React, { Fragment } from "react";
import StaticTab from "./StaticTab";

const TimeScalComp = ({ data }) => {
  const { timescale, remainingAmount } = data;
  if (timescale === 0) return null;

  const amountMonth = (remainingAmount / timescale).toFixed(1);

  const array = Array.from({ length: timescale }, (_, index) => ({
    header: `Mois ${index + 1}`,
    field: "result",
  }));

  const row = [{ result: amountMonth }];

  return (
    <Fragment>
      <div
        style={{
          fontSize: "16px",
        }}
      >
        Tableau d'échéancier
      </div>
      <StaticTab newColumns={array} newRows={row} />
    </Fragment>
  );
};

export default TimeScalComp;

import React, { Fragment } from "react";
import {
  Accordion,
  Avatar,
  Badge,
  Center,
  Group,
  Indicator,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import { IconCar } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  NO_TAXIMETER_FOUND,
  SIM_ICCID,
  SIM_PHONE,
  STCKER,
  STCKER_GOT_AT,
  STICKER_EXPIRE_AT,
  TAXI,
} from "Constant";

import { isEmpty } from "./isEmpty";
import Translate from "Components/Translate";

const TaxiComp = ({ data }) => {
  if (isEmpty(data)) return <Translate>{NO_TAXIMETER_FOUND}</Translate>;

  const {
    meid,
    imei,
    serial_number,
    model_taximeter,
    brand_taximeter,
    locked,
    active_taximeter: active,
    sticker,
    sticker_got_at,
    sticker_expire_at,
    sim_phone,
    sim_iccid,
    state_taximeter,
  } = data;

  const title = `${model_taximeter} /${brand_taximeter} /${serial_number}`;

  const indicatorProps = active
    ? { color: "green", label: "Active" }
    : { color: "red", label: "Not active" };

  const requiredKeys = [
    { title: TAXI, key: title },

    { title: "meid", key: meid },
    { title: "imei", key: imei },

    { title: SIM_PHONE, key: sim_phone },
    { title: SIM_ICCID, key: sim_iccid },

    { title: STCKER_GOT_AT, key: sticker_got_at },
    { title: STICKER_EXPIRE_AT, key: sticker_expire_at },
  ];

  const currentState =
    state_taximeter === "free" ? (
      <Badge color="teal">{state_taximeter}</Badge>
    ) : (
      <Badge color="red">{state_taximeter}</Badge>
    );

  return (
    <Fragment>
      <Accordion chevronPosition="left" variant="contained">
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Indicator color={indicatorProps.color}>
                  <Avatar radius="xl" size="lg" color="orange">
                    {<IconCar color="black" />}
                  </Avatar>
                </Indicator>

                <Fragment>
                  <Text size="xl" fw={700}>
                    {title}
                  </Text>
                  <Space h="xs" />
                  {currentState}
                </Fragment>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <SimpleTable requiredKeys={requiredKeys} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default TaxiComp;

import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import Breadcrumbs from "Components/Breadcrumbs";

import { MY_TAXIS } from "Constant";

import Main from "./Main";

const MonitoringPage = () => {
  const breadCrumbsProps = [MY_TAXIS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Main />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default MonitoringPage;

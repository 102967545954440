import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import ReadPageBtn from "../../../Components/DataTableBtns/ReadPageBtn";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import PdfBtn from "../../../Components/DataTableBtns/PdfBtn";

import { getCodeLabel, getTitleCodeLabel } from "../../../helper/getOptions";
import { z } from "zod";

// Urls
export const API = {
  list: "/back/taximeters",
  create: "/back/taximeter",
  edit: "/back/taximeter/",
  get: "/back/taximeter/",
  patch: "/back/tablet/",
  import: "/back/taximeters",
  Serial: "/back/taximeters/serials",
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  { name: "Status", selector: "status" },
  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },
  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn patch={API.patch + row.code} state={row.active} />
    ),
    button: "true",
  },
  {
    name: "PDF",
    cell: (row) => <PdfBtn row={row} extra={""} />,
    button: "true",
  },
  {
    name: "Read",
    cell: (row) => <ReadPageBtn read={`/taximeter/${row.code}`} />,
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => (
      <EditBtn read={API.get + row.code} extra={row.serial_number} />
    ),
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "state",
    title: "State",
    inputForm: "SelectInput",
    options: [
      {
        value: "internal_stock",
        label: "internal_stock",
        name: "state",
      },
      {
        value: "external_stock",
        label: "external_stock",
        name: "state",
      },
      {
        value: "sav",
        label: "sav",
        name: "state",
      },
      {
        value: "",
        label: "all",
        name: "state",
      },
    ],
  },

  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
  {
    title: "serial",
    placeholder: "serial",
    name: "serial",
    inputForm: "SimpleInput",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/taximeter",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Serial number",
    placeholder: "Serial number",
    name: "serial_number",
    inputForm: "SimpleInput",
  },
  {
    title: "Imei",
    placeholder: "Imei",
    name: "imei",
    inputForm: "SimpleInput",
  },
  {
    title: "Imei2",
    placeholder: "Imei2",
    name: "imei2",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Meid",
    placeholder: "Meid",
    name: "meid",
    inputForm: "SimpleInput",
    required: false,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/taximeter",
    getOptions: getTitleCodeLabel,
    required: false,
  },
  {
    title: "Serial number",
    placeholder: "Serial number",
    name: "serial_number",
    inputForm: "SimpleInput",
    required: false,
    // schema: z.any().optional(),
  },
  {
    title: "Imei",
    placeholder: "Imei",
    name: "imei",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Imei2",
    placeholder: "Imei2",
    name: "imei2",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Meid",
    placeholder: "Meid",
    name: "meid",
    inputForm: "SimpleInput",
    required: false,
  },
];

import React from "react";
import { Select } from "@mantine/core";
import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";

const SelectBar = ({ column, table, url }) => {
  const { data, isLoading } = useFetchData(url);

  // Transform data into the correct format and filter out duplicates
  const options = data
    ? Array.from(
        new Map(
          getData(data).map((item) => [
            item.code || item.value,
            {
              value: item.code || item.value || "", // Adjust key as needed
              label: item.label || item.name || "", // Adjust key as needed
            },
          ])
        ).values()
      )
    : [];

  const handleOnChange = (value) => {
    table.setColumnFilters([
      {
        id: column.id,
        value: value,
      },
    ]);
  };

  return (
    <Select
      loading={isLoading}
      onChange={handleOnChange}
      placeholder=""
      data={options}
      clearable
    />
  );
};

export default SelectBar;

import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid"; // Importing uuid for unique ID generation

const productData = () => ({
  id: uuidv4(), // Generate unique ID
  category: "",
  model: "",
  description: "",
  price: 0,
  quantity: 1,
  vat: 0,
  discount: 0,
});

const servicesData = () => ({
  id: uuidv4(), // Generate unique ID
  service: "",
  description: "",
  discount: 0,
  price: 0,
  quantity: 1,
  vat: 0,
});

const initialState = {
  vatAmount: 0,
  price: 0,
  priceVat: 0,
  products: [productData()],
  services: [servicesData()],
};

export const estimateSlice = createSlice({
  name: "estimate",

  initialState,

  reducers: {
    addProduct: (state) => {
      state.products.push(productData()); // Add new product with unique ID
    },
    addService: (state) => {
      state.services.push(servicesData()); // Add new service with unique ID
    },

    editProduct: (state, action) => {
      const { data, index } = action.payload;
      state.products[index] = { ...state.products[index], ...data };
    },

    editService: (state, action) => {
      const { data, index } = action.payload;
      state.services[index] = { ...state.services[index], ...data };
    },

    editProductPrice: (state, action) => {
      const { data, index } = action.payload;
      state.products[index] = { ...state.products[index], ...data };
    },

    onRemoveProduct: (state, action) => {
      const indexToRemove = action.payload;
      state.products = state.products.filter(
        (_, index) => index !== indexToRemove
      );
    },

    onRemoveService: (state, action) => {
      const indexToRemove = action.payload;
      state.services = state.services.filter(
        (_, index) => index !== indexToRemove
      );
    },

    fillOldData: (state, action) => {
      state.products = action.payload.products;
      state.services = action.payload.services;
    },

    refreshEstimate: (state) => {
      const { vatAmount, price, priceVat, products, services } = initialState;
      state.vatAmount = vatAmount;
      state.price = price;
      state.priceVat = priceVat;
      state.products = products;
      state.services = services;
    },
  },
});

export const {
  addProduct,
  addService,
  onRemoveService,
  onRemoveProduct,
  editProduct,
  editService,
  refreshEstimate,
  fillOldData,
  editProductPrice,
} = estimateSlice.actions;

export default estimateSlice.reducer;

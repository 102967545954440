export const getData = (param) => {
  try {
    return param.data.results.data;
  } catch {
    return [];
  }
};

export const getPriceVat = (param) => {
  try {
    const data = param.data.results.data;
    return {
      // price_ttc: data.price_ttc,
      vat: data.vat,
      price_ht: data.price_ht,
    };
  } catch {
    return {};
  }
};

import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import { activeObj } from "../../../Constant/objs";
import {
  getCodeLabel,
  getOptionNumber,
  getTitleCodeLabel,
} from "../../../helper/getOptions";

// Urls
export const API = {
  list: "/back/luminouss",
  GET_LUMINOUS_CHOICE: "/back/luminouss/choices",
  Serial: "/back/luminouss/serials",
  create: "/back/luminous",
  edit: "/back/luminous/",
  GET_LUMINOUS: "/back/luminous/",
  patch: "/back/luminous/",
  IMPORT_LUMINOUS: "/back/luminouss",
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },

  { name: "Status", selector: "status" },
  {
    name: "Number",
    selector: "serial_number",
  },

  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },
  { name: "Count leds", selector: "count_leds" },

  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn patch={API.patch + row.code} state={row.active} />
    ),
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => <EditBtn read={API.edit + row.code} extra={" "} />,
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  activeObj,
  { name: "brand", inputForm: "SelectInputGroups" },
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/luminous",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Firmware",
    name: "firmware",
    inputForm: "SelectApiInput",
    optionsApi: "/back/firmwares/choices?category=luminous",
    getOptions: getCodeLabel,
  },
  {
    name: "external",
    title: "External",
    inputForm: "SelectInput",
    options: [
      {
        value: true,
        label: "external",
        name: "external",
      },
      {
        value: false,
        label: "not external",
        name: "external",
      },
    ],
  },
  {
    title: "Count leds",
    name: "count_leds",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/luminous",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Firmware",
    name: "firmware",
    inputForm: "SelectApiInput",
    optionsApi: "/back/firmwares/choices?category=luminous",
    getOptions: getCodeLabel,
  },
  {
    name: "external",
    title: "External",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "external",
        name: "external",
      },
      {
        value: "false",
        label: "not external",
        name: "external",
      },
    ],
  },
  {
    title: "Count leds",
    name: "count_leds",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];

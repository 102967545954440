import React from "react";

import styles from "./estimate.module.css";

import PrintBtn from "Components/PrintBtn";
import { useReactToPrint } from "react-to-print";

const PrintEstiamte = ({ componentRef, estimateNumber }) => {
  const docTitle = `Devis_${estimateNumber}`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: docTitle,
  });

  return (
    <div className={styles["print-btn"]}>
      <PrintBtn onClick={handlePrint} />
    </div>
  );
};

export default PrintEstiamte;

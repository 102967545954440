import React from "react";
import { H1, H2, H3 } from "../../AbstractElements";
import { useTranslation } from "react-i18next";

const StaticTextRows = ({ requiredData }) => {
  const { t } = useTranslation();

  return requiredData.map((elem, index) => {
    const { text, type } = elem;

    switch (type) {
      case "H1":
        return <H1 key={index}> {t(text)} </H1>;
      case "H2":
        return <H2 key={index}> {t(text)} </H2>;
      case "H3":
        return <H3 key={index}> {t(text)} </H3>;
      case "strong":
        return <strong key={index}> {t(text)} </strong>;
      default:
        return <div key={index}> {t(text)}</div>;
    }
  });
};

export default StaticTextRows;

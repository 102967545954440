import React, { Fragment } from "react";
import ModalApp from "Components/ModalApp";
import EditItem from "Components/EditItemWithZod";

import { EDIT_ITEMS } from "./data";
import { EDIT_WORK_ORDER } from "Constant";

const EditWorkOrder = () => {
  return (
    <Fragment>
      <ModalApp type="edit" title={EDIT_WORK_ORDER}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default EditWorkOrder;

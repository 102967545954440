import SimpleForm from "Components/SimpleForm";
import { SCHEDULED_FOR } from "Constant";
import React from "react";
import { useSelector } from "react-redux";
import patchItemForm from "Services/patchItemForm";

const CreateDelivaryForm = () => {
  const { row } = useSelector((state) => state.currentRow);
  const url = `/back/purchaseorder/${row.code}/deliveryform`;

  const onSubmit = (data) => {
    patchItemForm(url, data);
  };

  return (
    <SimpleForm
      items={[
        {
          title: SCHEDULED_FOR,
          placeholder: "Scheduled for",
          name: "expectedDeliveryDate",
          inputForm: "DateInput",
          required: false,
        },
      ]}
      onSubmit={onSubmit}
      cancel={true}
    />
  );
};

export default CreateDelivaryForm;

import React from "react";

import { Controller } from "react-hook-form";
import useFetchData from "hooks/useFetchData";

import { getData } from "helper/getData";

import Translate from "Components/Translate";

import { TreeSelect } from "primereact/treeselect";

import { MODEL } from "Constant";

const SelectCarModel = ({ errors, oldValue, control }) => {
  const { data, isLoading, isError } = useFetchData("/back/models/choices/car");
  let options = [];

  if (!isLoading && !isError) options = getOptions(getData(data));

  console.log(options);

  if (isLoading) return null;
  return (
    <div className="col-3">
      <div className="flex flex-column ">
        <label>
          <Translate>{MODEL}</Translate>
          <span className="text-danger">*</span>
        </label>

        <Controller
          name="model"
          control={control}
          defaultValue={oldValue}
          render={({ field }) => (
            <TreeSelect
              loa
              options={options}
              id={field.model}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              filter
              showClear
            />
          )}
        />

        {errors["model"] && (
          <small className="text-danger">
            <Translate>{errors["model"].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};
export default SelectCarModel;

const getOptions = (param) => {
  return param.map((elem) => ({
    label: elem.label,
    children: elem.models.map((model) => ({
      key: model.code,
      label: model.label,
    })),
  }));
};

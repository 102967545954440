import { Paper, Text, Timeline } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useFetchData from "hooks/useFetchDataWithDep";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import Loading from "Components/Loading/Loading";
import Translate from "Components/Translate";
import EmptyData from "Components/EmptyData";
import {
  CLIENT,
  DRIVERS,
  EQUIPEMENT,
  GENERAL_DATA,
  SCHEDULES,
  SERVICES,
  TAXI,
  TAXIMETER,
  UPDATE_TARIFF,
} from "Constant";
import {
  IconCar,
  IconCircuitVoltmeter,
  IconClockDollar,
  IconTool,
  IconUser,
  IconUsersGroup,
  IconWifi,
} from "@tabler/icons-react";
import TaxiComp from "./TaxiComp";
import ClientComp from "./ClientComp";
import TaximeterComp from "./TaximeterComp";
import ServicesComp from "./ServicesComp";
import EquipmentsComp from "./EquipmentsComp";
import FormPupUp from "pages/SheetOfClientPage/FormPupUp";
import UsersComp from "./UsersComp";
import { Accordion, AccordionTab } from "primereact/accordion";
import ScheduleComp from "./ScheduleComp";
import { isEmptyRes } from "./isEmpty";
import UpdateBtn from "./UpdateBtn";

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

const SheetOfTaxiPage = () => {
  const { code } = useParams();
  const { t } = useTranslation();
  const { refresh } = useSelector((state) => state.searchLink);
  const url = `/back/taxi/${code}/sheet`;
  const { data, isLoading, isError } = useFetchData(url, refresh);
  const taxiSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;
  if (isError) return <EmptyData />;

  const { taxi, client, taximeter, users, services, equipments } = taxiSheet;

  const taximeterCode = taxiSheet?.taximeter?.code_taximeter ?? null;
  const pushTariffUrl = `/back/taximeter/${taximeterCode}/services`;
  const disabledUrl = taxiSheet?.taximeter?.state === "free" ? false : true;

  const stepsList = [
    {
      accordion: false,
      icon: <IconCar size={30} />,
      title: GENERAL_DATA,
      content: <TaxiComp data={taxi} taxiData={taxiSheet} />,
    },
    {
      accordion: false,
      icon: <IconUser size={30} />,
      title: CLIENT,
      content: <ClientComp data={client} />,
    },
    {
      accordion: false,
      empty: isEmptyRes(taximeter),
      icon: <IconCircuitVoltmeter size={30} />,
      title: TAXIMETER,
      content: (
        <>
          <TaximeterComp data={taximeter} />
          <UpdateBtn
            title={UPDATE_TARIFF}
            path={pushTariffUrl}
            disabled={disabledUrl}
          />
        </>
      ),
    },
    {
      icon: <IconUsersGroup size={30} />,
      title: DRIVERS,
      content: <UsersComp data={users} taxiData={taxiSheet} />,
    },
    {
      empty: isEmptyRes(services),
      icon: <IconWifi size={30} />,
      title: SERVICES,
      content: <ServicesComp data={services} taxiData={taxiSheet} />,
    },
    {
      icon: <IconClockDollar size={30} />,
      title: SCHEDULES,
      content: <ScheduleComp taxiCode={code} />,
    },
    {
      empty: isEmptyRes(equipments),
      icon: <IconTool size={30} />,
      title: EQUIPEMENT,
      content: <EquipmentsComp data={equipments} taxiData={taxiSheet} />,
    },
  ];

  const dynamicPart = `${t(TAXI)} ${taxi?.taxi_serial_number || ""}`;
  const breadCrumbsProps = [dynamicPart];

  return (
    <Fragment>
      <Paper shadow="xs" withBorder p="xl">
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

        <Timeline color="orange" active={stepsList.length} bulletSize={40}>
          {stepsList.map((elem, i) => {
            const title = (
              <Text fw={700} size="lg">
                <Translate>{elem.title}</Translate>
              </Text>
            );

            // Handle empty accordions to be open by default
            if (elem.empty) {
              return (
                <Timeline.Item bullet={elem.icon} key={i}>
                  <Accordion activeIndex={0}>
                    <AccordionTab header={title}>{elem.content}</AccordionTab>
                  </Accordion>
                </Timeline.Item>
              );
            }

            if (elem.accordion === false) {
              return (
                <Timeline.Item bullet={elem.icon} key={i}>
                  {elem.content}
                </Timeline.Item>
              );
            }

            return (
              <Timeline.Item bullet={elem.icon} key={i}>
                <Accordion activeIndex={-1}>
                  <AccordionTab header={title}>{elem.content}</AccordionTab>
                </Accordion>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Paper>
      <FormPupUp clientCode={client?.code_client} />
    </Fragment>
  );
};

export default SheetOfTaxiPage;

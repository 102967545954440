import React, { Fragment } from "react";
import {
  Accordion,
  Avatar,
  Center,
  Divider,
  Grid,
  Group,
  Image,
  Indicator,
  Paper,
  Space,
  Text,
} from "@mantine/core";

import {
  CLIENT_INFO,
  FULL_NAME,
  COMPANY,
  PHONE,
  REGISRTY,
  ADDRESS,
  CITY,
  ZIP_CODE,
  ACTIVE,
  PRO_CARD_GOT_AT,
  PRO_CARD_EXPIRE_AT,
  DOCUMENTS,
  THERE_IS_NO_DATA_FOUND,
  LICENCE,
  DISABLED,
  TESTER,
} from "Constant";

import { ClientSettingsBtn } from "./SettingsBtn";
import SimpleTable from "ComponentsMantine/SimpleTable";
import Translate from "Components/Translate";

const ClientInfo = ({ data, toClientSheet, defaultValue }) => {
  const fullName = data.full_name ?? `${data.firstname} ${data.lastname}`;

  // "code_client": "cc3d2623-0b10-499f-959f-fb37031881be",
  // "full_name": "M XYZ XYZ",
  // "complete_company": " 2356659465",
  // "complete_address": "34 BD Fernand Hostachy Croissy sur seine 78290",
  // "phone": "93234428",
  // "pro_card": "123124262/2010-12-31/2030-12-31",
  // "client_active": true
  const {
    company,
    phone,
    registry,
    address,
    city,
    zip_code,
    pro_card,
    pro_card_got_at,
    pro_card_expire_at,
    client_active,
    image,
    tester,
    client_cni,
    client_licence,
    complete_company,
    complete_address,
  } = data;

  const clientPersonelData = data.full_name
    ? [
        { title: FULL_NAME, key: fullName },

        { title: COMPANY, key: complete_company },
        { title: ADDRESS, key: complete_address },
        { title: PHONE, key: phone },
        { title: "Pro card", key: pro_card },
      ]
    : [
        { title: FULL_NAME, key: fullName },
        { title: TESTER, key: tester },
        { title: COMPANY, key: company },

        { title: PHONE, key: phone },
        { title: REGISRTY, key: registry },
        { title: ADDRESS, key: address },
        { title: CITY, key: city },
        { title: ZIP_CODE, key: zip_code },
        {
          title: "Pro card",
          key: pro_card,
        },
        {
          title: PRO_CARD_GOT_AT,
          key: pro_card_got_at,
        },
        {
          title: PRO_CARD_EXPIRE_AT,
          key: pro_card_expire_at,
        },
      ];

  const indicatorProps = client_active
    ? { color: "green", label: <Translate>{ACTIVE}</Translate> }
    : { color: "red", label: <Translate>{DISABLED}</Translate> };

  const title = <Text fw={700}> {fullName} </Text>;

  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={defaultValue}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Indicator
                  // inline
                  label={indicatorProps.label}
                  color={indicatorProps.color}
                  // size={16}
                >
                  <Avatar radius="xl" src={image} size="lg" color="orange" />
                </Indicator>

                <Space w="md" />

                <div>
                  <Text size="xl">{title}</Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <ActionIcon size="lg" variant="subtle" color="gray"> */}
            <ClientSettingsBtn data={data} toClientSheet={toClientSheet} />

            {/* </ActionIcon> */}

            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <Divider
                my="xs"
                label={<Text fw={700}> {CLIENT_INFO} </Text>}
                labelPosition="center"
              />
              <SimpleTable requiredKeys={clientPersonelData} />
              {/* client_cni client_licence */}
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default ClientInfo;

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Space } from "@mantine/core";
import { editService } from "../../redux/estimateSlicer";
import { Panel } from "primereact/panel";

import { getPriceVat } from "helper/getData";
import axiosClient from "Services/axiosClient";

import Translate from "Components/Translate";
import {
  DESCRIPTION,
  DISCOUNT,
  DISCOUNTED_PRICE,
  MONTH,
  SERVICE,
  TTC,
  UNITY_PRICE,
  VAT,
} from "Constant";

import { DeleteServiceBtn } from "./EstimatesBtns";
import SelectApiInput from "./SelectApiInput";
import { getNumber, serviceInp } from "./data";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { calculateProduct } from "helper/estimateFx";

const initPrices = {
  price_ttc: 0,
  vat: 0,
  price_ht: 0,
};

const initInp = () => ({
  service: "",
  description: "",
  dateBegin: "",
  dateEnd: "",
  discount: 0,
  price: 0,
  quantity: 1,
  vat: 0,
});

export default function OneProduct({ index, oldData }) {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState(oldData ? oldData : initInp);
  const [prices, setPrices] = useState(initPrices);

  const { vat, price_ht } = prices;

  const price = calculateProduct(price_ht, 0, inputs.quantity, inputs.discount);
  const price_ttc = calculateProduct(
    price_ht,
    vat,
    inputs.quantity,
    inputs.discount
  );

  const finalRes = useMemo(
    () => ({
      ...inputs,
      price,
      price_ttc,
      vat,
    }),
    [inputs, price, price_ttc, vat]
  );

  useEffect(() => {
    if (inputs.service) {
      axiosClient(`/back/service/${inputs.service}/price`)
        .then((res) => {
          setPrices(getPriceVat(res));
        })
        .catch((error) => {
          setPrices(initPrices);
          console.error(error);
        });
    }
  }, [inputs.service]);

  useEffect(() => {
    dispatch(editService({ data: finalRes, index: index }));
  }, [dispatch, finalRes, index]);

  return (
    <Fragment>
      <Panel
        headerTemplate={
          <div className="p-panel-header justify-content-space-between">
            <div className="flex align-items-center ">
              <span className="font-bold">
                <Translate>{SERVICE}</Translate>: {index + 1}
              </span>
            </div>
            <div>
              <DeleteServiceBtn i={index} />
            </div>
          </div>
        }
        toggleable
      >
        <div className="grid">
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{SERVICE}</Translate>
              </label>
              <SelectApiInput
                register={(e) => {
                  const newVal = { [e.name]: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                inputName={serviceInp}
                oldValue={inputs["service"]}
              />
            </div>
          </div>

          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{MONTH}</Translate>
              </label>
              <InputNumber
                onValueChange={(e) => {
                  const newVal = { quantity: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                min={1}
                showButtons
                size={5}
                value={inputs["quantity"]}
              />
            </div>
          </div>

          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{DISCOUNT}</Translate>
              </label>
              <InputNumber
                onValueChange={(e) => {
                  const newVal = e.value
                    ? { discount: parseFloat(e.value.toFixed(2)) }
                    : { discount: 0 };

                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                mode="decimal"
                minFractionDigits={1}
                maxFractionDigits={2}
                min={0}
                max={100}
                suffix="%"
                showButtons
                step={1}
                size={5}
                value={inputs["discount"]}
              />
            </div>
          </div>

          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{DESCRIPTION}</Translate>
              </label>
              <InputText
                onChange={(e) => {
                  const newVal = { description: e.target.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                value={inputs["description"]}
              />
            </div>
          </div>

          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{UNITY_PRICE}</Translate>
              </label>
              <InputNumber
                value={getNumber(price_ht)}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>

          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{DISCOUNTED_PRICE}</Translate>
              </label>
              <InputNumber
                value={getNumber(finalRes["price"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>

          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{VAT}</Translate>
              </label>
              <InputNumber
                value={getNumber(vat)}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
                suffix="%"
              />
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label>
                <Translate>{TTC}</Translate>
              </label>
              <InputNumber
                value={getNumber(finalRes["price_ttc"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
        </div>
      </Panel>

      <Space h="lg" />
    </Fragment>
  );
}

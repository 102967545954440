import React from "react";
import { Avatar, Grid, Group, Text } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";

const UserAvatar = ({ firstname, lastname, img, phone }) => {
  const fullName = `${firstname} ${lastname}`;
  return (
    <Group wrap="nowrap">
      <Avatar src={img} radius="xl" size="lg" />
      <div>
        <Text>{fullName}</Text>
        <Text size="sm" c="dimmed" fw={400}>
          {phone}
        </Text>
      </div>
    </Group>
  );
};

export default UserAvatar;

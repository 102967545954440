import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import ImportExcel from "../../Components/ImportExcel";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import {
  API,
  COLUMNS,
  CREATE_ITEMS,
  EDIT_ITEMS,
  SEARCH_ITEMS,
} from "./data/Taximeter";

import {
  CREATE_TAXIMETERS,
  EDIT_TAXIMETERS,
  MY_TAXIMETERS,
  PDF,
} from "../../Constant";
import GenerateSerials from "../../Components/GenerateSerials";
import PDFTaximeter from "./PDFTaximeter";

const Taximeter = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_TAXIMETERS];

  const handleImport = (data) => `{"data":${data}}`;
  const getOldData = (data) => {
    console.log("dataaa", data);
    return { ...data, model: data.model, serial_number: data.serialNumber };
  };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} importBtn={true} serialBtn={true} />

          <FilterBar searchByItems={SEARCH_ITEMS} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_TAXIMETERS}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_TAXIMETERS}>
        <EditItem itemsUpdate={EDIT_ITEMS} getOldData={getOldData} />
      </ModalApp>

      <ModalApp type="import">
        <ImportExcel
          importApi={API.import}
          file_name="Taximeters"
          handleImport={handleImport}
        />
      </ModalApp>

      <ModalApp type="QRcode">
        <GenerateSerials serialURL={API.Serial} />
      </ModalApp>

      <ModalApp type="PDF" title={PDF}>
        <PDFTaximeter />
      </ModalApp>
    </Fragment>
  );
};

export default Taximeter;

import React, { Fragment } from "react";

import StaticTextTable from "Components/StaticData/StaticTextTable";

import { PRICE, PRICE_TTC, TOTAL, VAT } from "Constant";

const TotalResultArray = ({ result }) => {
  const { vatAmount, price, priceVat } = result;

  const totalData = [
    { label: PRICE, value: price },
    { label: VAT, value: vatAmount },
    { label: PRICE_TTC, value: priceVat },
  ];

  return (
    <Fragment>
      <StaticTextTable requiredData={totalData} title={TOTAL} />
    </Fragment>
  );
};

export default TotalResultArray;

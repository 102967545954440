import { Fragment, useEffect, useState } from "react";
import { io } from "socket.io-client";
import MapApp from "./MapApp";
import Loading from "Components/Loading/Loading";

// const urlOperator = "https://bookingskt.taxiora.tn/operator";
const urlOperator = process.env.REACT_APP_URL_SOCKET;

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("Disconnected");
  const [taxiData, setTaxiData] = useState([]);
  const operatorCode = "TAXIORA";
  // const operatorCode = "32b1348e-4690-4055-8041-f76fda06d8e9";

  let socket;

  useEffect(() => {
    socket = io(urlOperator);

    socket.on("connect", () => {
      console.log("Connected to Socket.IO");
      setStatus(`Connected (ID: ${socket.id})`);
      // Request taxi data
      socket.emit("getTaxis", { operator_code: operatorCode });
    });

    socket.on("dataResponse", (data) => {
      setTaxiData(data);
      setIsLoading(false); // Set loading to false when data is received
    });

    socket.on("connect_error", (error) => {
      console.error("Connection error:", error);
      setStatus("Connection Error");
      setIsLoading(false); // Stop loading spinner on connection error
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
      setStatus("Socket Error");
      setIsLoading(false); // Stop loading spinner on socket error
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO");
      setStatus("Disconnected");
      setIsLoading(false); // Stop loading spinner when disconnected
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return isLoading ? <Loading /> : <MapApp taxis={taxiData} />;
};

export default Main;

import { z } from "zod";

import { civilityObj, testerObj } from "Constant/objs";
import { getCarModel, getCodeLabel } from "helper/getOptions";
import {
  CLIENT,
  PRO_CARD_EXPIRE_AT,
  PRO_CARD_GOT_AT,
  TAXI,
  TESTER,
} from "Constant";
import { Divider } from "primereact/divider";
import Translate from "Components/Translate";

export const CREATE_ClIENT = [
  {
    inputForm: "CustomComponant",
    comp: (
      <Divider align="left">
        <div className="inline-flex align-items-center">
          <i className="pi pi-user mr-2"></i>
          <b>
            <Translate>{CLIENT}</Translate>
          </b>
        </div>
      </Divider>
    ),
  },
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Email",
    placeholder: "email",
    name: "mail",
    inputForm: "SimpleInput",
    schema: z.optional(z.string()),
    required: false,
  },

  civilityObj,
  {
    title: "Birth date",
    placeholder: "Birth date",
    name: "birthDate",
    inputForm: "DateInput",
  },
  {
    title: "Company",
    placeholder: "Company",
    name: "company",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
    schema: z
      .string()
      .min(1, { message: "This field is required" })
      .transform((val) => val.replace(/\s+/g, "")),
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "Zip code",
    name: "zip_code",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "City",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Pro card",
    placeholder: "Pro card",
    name: "pro_card",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: PRO_CARD_GOT_AT,
    placeholder: "Pro card got at",
    name: "pro_card_got_at",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: PRO_CARD_EXPIRE_AT,
    placeholder: "Pro card expire At",
    name: "pro_card_expire_at",
    inputForm: "DateInput",
    required: false,
  },
  testerObj,

  // CREATE TAXI

  {
    inputForm: "CustomComponant",
    comp: (
      <Divider align="left">
        <div className="inline-flex align-items-center">
          <i className="pi pi-car mr-2"></i>
          <b>
            <Translate>{TAXI}</Translate>
          </b>
        </div>
      </Divider>
    ),
  },

  {
    title: "Registration number",
    placeholder: "Registration number",
    name: "registrationNumber",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInputGroup",
    optionsApi: "/back/models/choices/car",
    getOptions: getCarModel,
  },
  {
    title: "Profile",
    name: "profile",
    inputForm: "SelectApiInput",
    optionsApi: "/back/profiles/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Places",
    placeholder: "Places",
    name: "places",
    inputForm: "SimpleInput",
  },
  {
    title: "Serial number",
    placeholder: "serial number",
    name: "serialNumber",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Licence",
    placeholder: "Licence",
    name: "licenceNumber",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Licence got at",
    placeholder: "licenceGotAt",
    name: "licenceGotAt",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Licence expire at",
    placeholder: "licenceExpireAt",
    name: "licenceExpireAt",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Circulation date",
    placeholder: "circulationDate",
    name: "circulationDate",
    inputForm: "DateInput",
    required: false,
  },
];

import { Group } from "@mantine/core";
import ActiveBtn from "../../Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "../../Components/MantineDataTableBtns/EditBtn";
import { activeObj } from "../../Constant/objs";
import { fullName } from "../../helper/fullName";
import { getCodeLabel } from "../../helper/getOptions";
import UserAvatar from "./UserAvatar";
import { ACTIVE, USERS } from "Constant";

// API
export const API = {
  list: "/back/backusers",
  get: "/back/backuser",
  // GET_BACKUSER_AGENCIES: "/back/backuser/",
  create: "/back/backuser",
  //GROUPS_CHOICES: "/back/groups",
};

// Columns
export const COLUMNS = [
  {
    header: USERS,
    accessorFn: (originalRow) => originalRow.status,
    enableColumnFilter: true,
    id: "User",
    // size: 200,
    Cell: ({ row }) => {
      const { firstname, lastname, img, phone } = row.original;
      return (
        <UserAvatar
          firstname={firstname}
          lastname={lastname}
          img={img}
          phone={phone}
        />
      );
    },
  },
  {
    name: "Login",
    selector: "login",
  },
  { name: "Mail", selector: "mail" },
  { name: "Role", selector: "role" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { lastname, code, active, firstname } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.get + "/" + code} state={active} />
          <EditBtn
            read={API.get + "/" + code}
            extra={fullName(firstname, lastname)}
          />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Email",
    type: "email",
    placeholder: "Email",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone number",
    placeholder: "Phone number",
    name: "phone",
    inputForm: "SimpleInput",
    type: "number",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectApiInput",
    optionsApi: "/back/groups",
    getOptions: getCodeLabel,
  },
  {
    title: ACTIVE,
    name: "active",
    inputForm: "CheckBox",
    required: false,
  },
];

export const EDIT_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Email",
    type: "email",
    placeholder: "Email",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Téléphone",
    placeholder: "Téléphone",
    name: "phone",
    inputForm: "SimpleInput",
    type: "number",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectApiInput",
    optionsApi: "/back/groups",
    getOptions: getCodeLabel,
  },
  {
    title: ACTIVE,
    name: "active",
    inputForm: "CheckBox",
    required: false,
  },
];

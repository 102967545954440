import { Badge } from "@mantine/core";
import { getStatusColor } from "helper/getColor";
import React from "react";

const StatusColumn = ({ code, label }) => {
  return (
    <Badge color={getStatusColor(code)} size="lg">
      {label ?? code}
    </Badge>
  );
};

export default StatusColumn;

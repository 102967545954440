import { useState } from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { SegmentedControl } from "@mantine/core";

import useFetchData from "hooks/useFetchData";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import { Card, CardBody, Col, Row } from "reactstrap";

import {
  CREATED_AT,
  UPDATED_AT,
  TIME_ZONE,
  PAUSE,
  RULES,
  VALUES,
  ACTIVE,
  LED,
  OWNER_SHARE,
} from "Constant";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import { AiOutlineIdcard } from "react-icons/ai";
import HeaderTabs from "Components/Header/HeaderTabs";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import RulesTable from "./RulesTable";
import ValuesTable from "./ValuesTable";

const TariffPage = () => {
  const { code } = useParams();

  const [state, setState] = useState("rules");

  const componants = {
    rules: <RulesTable triffCode={code} />,
    values: <ValuesTable triffCode={code} />,
  };

  const { data, isLoading, isError } = useFetchData("/back/tariff/" + code);

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  const profileDetails = getProfileDetails(data);

  const breadCrumbsProps = [profileDetails.label];

  const requiredKeys = [
    { key: "createdAt", name: CREATED_AT, icon: <AiOutlineIdcard /> },
    { key: "active", name: ACTIVE, icon: <AiOutlineIdcard /> },
    { key: "led", name: LED, icon: <AiOutlineIdcard /> },
    { key: "ownerShare", name: OWNER_SHARE, icon: <AiOutlineIdcard /> },
  ];
  return (
    <>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Row>
            <Col sm="2">
              {!isLoading && (
                <HeaderTabs
                  title={breadCrumbsProps}
                  objectData={profileDetails}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col sm="10">
              <Card className="mb-0">
                <CardBody>
                  <SegmentedControl
                    transitionDuration={200}
                    transitionTimingFunction="linear"
                    color="yellow"
                    fullWidth
                    size="md"
                    value={state}
                    onChange={setState}
                    data={[
                      { label: RULES, value: "rules" },
                      { label: VALUES, value: "values" },
                    ]}
                  />
                  <Divider my="md" />

                  {componants[state]}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default TariffPage;

const getProfileDetails = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return {};
  }
};

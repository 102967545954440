import { Fragment } from "react";

import ExportRowsPdf from "Components/MantineDataTableBtns/ExportRowsPdf";
import DataTables from "Components/DataTables/DataTable";
import UploadDoc from "Components/UploadDoc";
import ModalApp from "Components/ModalApp";

import { COLUMNS } from "./data";

const TaxiSchedule = ({ taxiCode }) => {
  const list = `/back/schedules`;

  const mantineTableBodyRowProps = ({ row }) => {
    const { color } = row?.original;
    if (color) {
      return {
        style: {
          backgroundColor: color,
        },
      };
    }
    return row;
  };

  const defSorting = [{ id: "created_at", desc: false }];

  const defColumnFilters = [
    {
      id: "taxi",
      value: taxiCode,
    },
  ];

  const columnPinning = {
    left: ["paid"],
  };

  return (
    <Fragment>
      <DataTables
        newColumns={COLUMNS}
        link={list}
        createBtn={false}
        mantineTableBodyRowProps={mantineTableBodyRowProps}
        defColumnFilters={defColumnFilters}
        defSorting={defSorting}
        CustomComponent={ExportRowsPdf}
        columnPinning={columnPinning}
      />

      <ModalApp type="setSchedulePaid" title="Envoyer la preuve de paiement du">
        <UploadDoc fileName="proof" />
      </ModalApp>
    </Fragment>
  );
};

export default TaxiSchedule;

import React from "react";
import { Button } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";

const ReadSheetBtn = ({ read: path, title }) => {
  return (
    <Button
      variant="default"
      color="green"
      radius="xl"
      leftSection={<IconEye size={14} />}
      onClick={() => {
        window.open(path, "_blank");
      }}
    >
      {title}
    </Button>
  );
};

export default ReadSheetBtn;

import { Group } from "@mantine/core";

import { createdAt, updatedAt } from "Constant/objs";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

import { getCodeLabel } from "helper/getOptions";

import { LINKED_TO_TAXIMTER, PHONE } from "Constant";

import HandleResult from "helper/HandleResult";

// Urls
export const API = {
  list: "/back/sims",
  choice: "/back/sims/choices",
  Serial: "/back/sims/serials",
  create: "/back/sim",
  get: "/back/sim/",
  edit: "/back/sim/",
  patch: "/back/sim/",
  import: "/back/sims",
};

// Columns
export const COLUMNS = [
  // {
  //   name: <SelectAll>generate all</SelectAll>,
  //   cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
  //   button: "true",
  // },

  { name: "iccid", selector: "iccid" },
  { name: PHONE, selector: "phone" },
  { name: "puk", selector: "puk" },
  {
    name: LINKED_TO_TAXIMTER,
    Cell: ({ row }) => (
      <HandleResult data={row.original.is_linked_to_taximeter} />
    ),
  },

  createdAt,
  updatedAt,

  {
    id: "actions",
    button: "true",
    Cell: ({ row }) => {
      const { code, active, serial_number } = row.original;
      return (
        <Group gap="xs">
          <EditBtn read={API.get + code} extra={serial_number} />
          <ActiveBtn patch={API.patch + code} state={active} />
        </Group>
      );
    },
  },
];

export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "iccid",
    placeholder: "iccid",
    name: "iccid",
    inputForm: "SimpleInput",
  },
  {
    title: "puk",
    placeholder: "puk",
    name: "puk",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "iccid",
    placeholder: "iccid",
    name: "iccid",
    inputForm: "SimpleInput",
  },
  {
    title: "puk",
    placeholder: "puk",
    name: "puk",
    inputForm: "SimpleInput",
  },
];

import React from "react";
import { ActionIcon } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

import deleteItem from "Services/deleteItem";

const DeleteBtn = ({ delete: deleteApi, isDisable }) => {
  return (
    <ActionIcon
      variant="light"
      color="red"
      aria-label="Settings"
      onClick={() => deleteItem(deleteApi)}
      disabled={isDisable}
    >
      <IconTrash />
    </ActionIcon>
  );
};

export default DeleteBtn;

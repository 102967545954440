import {
  Avatar,
  Divider,
  Group,
  List,
  rem,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconCircleCheck, IconCircleDashed } from "@tabler/icons-react";
import { H3, Image, P } from "AbstractElements";
import Breadcrumbs from "Components/Breadcrumbs";
import { MY_PROFILE } from "Constant";
import useLocalStorage from "hooks/useLocalStorage";
import UserAvatar from "pages/UserPage/UserAvatar";
import React from "react";
import { Card, CardBody } from "reactstrap";

const MyProfileEdit = () => {
  const breadCrumbsProps = [MY_PROFILE];

  const { getData } = useLocalStorage();
  const { firstname, lastname, img, role, mail, login } = getData("userData");
  const data = getData("userData");

  console.log(data);

  return (
    <Card className="mb-0">
      <CardBody>
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
        <Group wrap="nowrap">
          <Avatar variant="filled" radius="xl" size="xl" src={img} />
          <div>
            <Text fw={700} size="xl">{`${firstname} ${lastname}`}</Text>
            <Text size="sm" c="dimmed" fw={400}>
              {role}
            </Text>
          </div>
        </Group>
        <Divider my="md" />

        <List
          spacing="xs"
          size="xl"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
        >
          <List.Item>-</List.Item>
        </List>
      </CardBody>
    </Card>
  );
};

export default MyProfileEdit;

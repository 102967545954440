import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import { getCodeLabel, getTitleCodeLabel } from "../../../helper/getOptions";

// Urls
export const API = {
  list: "/back/sosButtons",
  choice: "/back/sosButtons/choices",
  Serial: "/back/sosButtons/serials",
  create: "/back/sosButton",
  get: "/back/sosButton/",
  edit: "/back/sosButton/",
  patch: "/back/sosButton/",
  import: "/back/sosButtons",
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: "Status",
    selector: "status",
  },
  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },

  {
    name: "Edit",
    cell: (row) => (
      <EditBtn read={API.get + row.code} extra={row.serial_number} />
    ),
    button: "true",
  },
];

export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/sos_button",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    inputForm: "TitleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/sos_button",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    inputForm: "TitleInput",
  },
];

import React from "react";
import { InputText } from "primereact/inputtext";

const SearchBar = ({ setSearch }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "20px", // Distance from the bottom
        left: "20px", // Position the search bar on the left instead of the right
        backgroundColor: "transparent",
        padding: "10px 15px", // Adjusted padding for better spacing
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
        backdropFilter: "blur(10px)",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: 5,
          width: "15vw",
        }}
      >
        <InputText
          placeholder="Recherche par taxi"
          autoComplete="off"
          onChange={(e) => setSearch(e.target.value)}
          size={10}
        />
      </div>
    </div>
  );
};

export default SearchBar;

import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS } from "./data";

import { CREATE_WORK_ORDERS, MY_WORK_ORDERS } from "Constant";

import CreateWorkOrder from "./CreateWorkOrder";
import EditWorkOrder from "./EditWorkOrder";

const WorkOrdersPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_WORK_ORDERS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_WORK_ORDERS}>
        <CreateWorkOrder />
      </ModalApp>
      <EditWorkOrder />
    </Fragment>
  );
};

export default WorkOrdersPage;

import { Group } from "@mantine/core";
import CartBtn from "Components/DataTableBtns/CartBtn";

import { getCodeLabel, getOptionNumber } from "helper/getOptions";

import { MODEL, QUANTITY, QUANTITY_USED } from "Constant";

import { createdAt, updatedAt } from "Constant/objs";

import CategoryFilter from "./CategoryFilter";

// Urls
export const API = {
  create: "/back/rawmaterial",
  get: "/back/rawmaterial/categories",
  list: "/back/rawmaterials",
};

// Columns
export const COLUMNS = [
  {
    accessorFn: (row) => row.status,
    id: "category",
    header: "Category",
    filterFn: "equals",

    Filter: ({ column, header, table }) => {
      try {
        return <CategoryFilter column={column} header={header} table={table} />;
      } catch {
        return <></>;
      }
    },

    Cell: ({ row }) => {
      const { category } = row.original;
      return category;
    },
  },
  {
    id: "model",
    name: MODEL,
    selector: "model",
    enableColumnFilter: false,
  },
  { name: QUANTITY, selector: "amount" },
  { name: QUANTITY_USED, selector: "amount_used" },
  createdAt,
  updatedAt,

  // {
  //   name: "PDF",
  //   cell: (row) => (
  //     <PdfBtn row={row} extra={fullName(row.category, row.model)} />
  //   ),
  //   button: "true",
  // },
  // {
  //   name: "Purchase order",
  //   cell: (row) => <CartBtn row={row} />,
  //   button: "true",
  // },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    enablePinning: true,
    size: 80,
    Cell: ({ row }) => {
      const { category, model } = row.original;
      return (
        <Group gap="xs">
          <CartBtn row={row.original} />
        </Group>
      );
    },
    columnDefProps: {
      className: "pinned-actions-column",
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 10000),
    number: true,
  },

  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
];

export const EDIT_ITEMS = [
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
  },
];

export const CART_ITEMS = [
  {
    title: "Quantity",
    placeholder: "quantity",
    name: "quantity",
    inputForm: "SimpleInput",
  },
];

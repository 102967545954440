import TaxisPage from "pages/TaxisPage";
import MonitoringPage from "pages/MonitoringPage";
import TaximeterProblemsPage from "pages/TaximeterProblemsPage";
import TaximeterAlertPage from "pages/TaximeterAlertPage";
import TaximetersPage from "pages/TaximetersPage";
import SheetOfTaximeterPage from "pages/SheetOfTaximeterPage";

const EXPLOITATION_ROUTES = [
  {
    path: "/taxis",
    element: <TaxisPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taximeters",
    element: <TaximetersPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taximeter/:code",
    element: <SheetOfTaximeterPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/monitoring",
    element: <MonitoringPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taximter-problems",
    element: <TaximeterProblemsPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/taximter-alert",
    element: <TaximeterAlertPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
];

export default EXPLOITATION_ROUTES;

import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import Translate from "Components/Translate";
import { Controller } from "react-hook-form";

const CheckBox = ({ register, inputName, errors, oldValue, control }) => {
  const { name, title, required } = inputName;
  const isReq = required !== undefined ? required : true;

  return (
    <div className="col-3">
      <div className="flex flex-column">
        <label>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>

        <Controller
          defaultValue={oldValue}
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <InputSwitch
                id={field.name}
                checked={field.value}
                onClick={(e) => {
                  field.onChange(!field.value);
                }}
              />
            );
          }}
        />

        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name]?.message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default CheckBox;

import Breadcrumbs from "Components/Breadcrumbs";
import DataTable from "Components/DataTables/DataTable";
import { TAXIMETER_PROBLEMS } from "Constant";
import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import { API, COLUMNS } from "./data";

const TaximeterProblemsPage = () => {
  const list = API.list;
  const breadCrumbsProps = [TAXIMETER_PROBLEMS];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTable
            columnSearch={false}
            newColumns={COLUMNS}
            link={list}
            createBtn={false}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TaximeterProblemsPage;

import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";

import { API, COLUMNS } from "./data";
import CreateBtn from "./CreateBtn";

import { MY_ESTIMATE } from "Constant";

const EstimatePage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_ESTIMATE];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables
            newColumns={COLUMNS}
            link={list}
            createBtn={<CreateBtn />}
            globalSearch={false}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EstimatePage;
